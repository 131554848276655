import React, { useEffect, useState } from "react";
import { Divider, Typography, Paper } from "@material-ui/core";
import moment from "moment";
import { Card, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import useStyles from "./clientDetailsStyles.js";
import { getPost } from "../../../actions/posts.js";
import sidebar5 from "../../../assets/img/sidebar-5.jpg";

const ClientDetailsCard = () => {
  const { post, isLoading } = useSelector((state) => state.posts);
  // console.log(post?.sessionList, "This is the session list");
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));

  const [sidebarImage, setSidebarImage] = useState(sidebar5);
  const [sidebarBackground, setSidebarBackground] = useState("black");
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPost(id, user?.result?._id, user?.result?.user_role));
  }, [id]);

  if (!post) return null;

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (user?.result?._id == null) {
        return <Route component={() => <Auth />} />;
      } else {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              component={prop.component}
            />
          );
        } else {
          return null;
        }
      }
    });
  };

  if (isLoading) {
    return (
      <div
        style={{
          marginTop: "70px",
          top: "50%",
          left: "50%",
          position: "fixed",
        }}
      >
        <Spinner animation="grow" size="lg" variant="primary" />
      </div>
    );
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="wrapper">
      <Sidebar
        routes={routes}
        image={sidebarImage}
        background={sidebarBackground}
      />
      <div className="main-panel">
        <AdminNavbar />
        <div className="content">
          <Paper
            style={{ padding: "20px", borderRadius: "15px" }}
            elevation={6}
          >
            <div className={classes.card}>
              <div className={classes.section}>
                <Typography variant="h5" elevation={4}>
                  <strong style={{ color: "#195400" }}>
                    {post?.clientName}
                  </strong>
                </Typography>
                {/* <Typography gutterBottom variant="h6" color="textSecondary" component="h2">{post?.tags.map((tag) => `#${tag} `)}</Typography> */}
                <Divider style={{ margin: "20px 0" }} />
                <Typography variant="body2">
                  <strong>Case ID:</strong> {post?._id}
                </Typography>
                <Typography variant="body2">
                  <strong>Case Source: </strong>
                  {post?.caseSource}
                </Typography>
                <Typography variant="body2">
                  <strong>Mobile:</strong> 0{post?.mobile}
                </Typography>
                <Typography variant="body2">
                  <strong>Language:</strong> {post?.language?.toString()}
                </Typography>
                <Typography variant="body2">
                  <strong>Nationality:</strong> {post?.nationality}
                </Typography>
                <Typography variant="body2">
                  <strong> Caller name:</strong> {post?.callerName}
                </Typography>
                <Typography variant="body2">
                  <strong> Caller age:</strong> {post?.callerAge}
                </Typography>
                <Typography variant="body2">
                  <strong>Caller Different From Client:</strong>{" "}
                  {post?.sameAsCaller}
                </Typography>
                <Typography variant="body2">
                  <strong> Relationship:</strong>{" "}
                  {post?.relationship ? post?.relationship : "Not Provided"}
                </Typography>
                <Typography variant="body2">
                  <strong> Client Sex:</strong>{" "}
                  {post?.clientSex ? post?.clientSex : "Not Provided"}
                </Typography>
                <Typography variant="body2">
                  <strong> Client age:</strong>{" "}
                  {post?.clientAge ? post?.clientAge : "Not Provided"}
                </Typography>
                <Typography variant="body2">
                  <strong> Case Assessment:</strong>{" "}
                  {post?.caseAssessment?.toString()
                    ? post?.caseAssessment?.toString()
                    : "Not Assessed!"}
                </Typography>
                <Typography variant="body2">
                  <strong> Case Duration:</strong>{" "}
                  {post?.howLong ? post?.howLong : "Not Provided"}
                </Typography>

                <Divider style={{ margin: "20px 0" }} />
                <Typography variant="body2">
                  <strong>Region:</strong> {post?.region}
                </Typography>
                <Typography variant="body2">
                  <strong>Peer Referral:</strong> {post?.peerReferral}
                </Typography>
                <Typography variant="body2">
                  <strong>District:</strong> {post?.clientDistrict}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ textTransform: "capitalize" }}
                >
                  <strong>Counselor:</strong> {post?.name}
                </Typography>
                <Divider style={{ margin: "20px 0" }} />
                <Typography variant="body2">
                  <strong>Date Created</strong>{" "}
                  {moment(post?.createdAt).fromNow()}
                </Typography>
                <Divider style={{ margin: "20px 0" }} />
              </div>

              {/* <div className={classes.imageSection}> */}
              {/* <img className={classes.media} src={post?.selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} alt={post?.title} /> */}
              <Card className={classes.cardSection} elevation={8}>
                <Typography
                  className={classes.messageText}
                  variant="body1"
                  component="p"
                  style={{ color: "#fff" }}
                >
                  <strong style={{ color: "#5096D1" }}>Difficulty: </strong>
                  {post?.difficulty?.toString()}
                </Typography>
                <Typography
                  className={classes.messageText}
                  variant="body1"
                  component="p"
                  style={{ color: "#fff" }}
                >
                  <strong style={{ color: "#5096D1" }}>Reason: </strong>
                  {post?.reason}
                </Typography>
                <Typography
                  className={classes.messageText}
                  variant="body1"
                  component="p"
                  style={{ color: "#fff", textTransform: "capitalize" }}
                >
                  <strong style={{ color: "#5096D1" }}>Prior Services: </strong>
                  {post?.servicesPrior?.toString()}
                </Typography>
                <Typography
                  className={classes.messageText}
                  variant="body1"
                  component="p"
                  style={{ color: "#fff", textTransform: "capitalize" }}
                >
                  <strong style={{ color: "#5096D1" }}>
                    Services Offered:{" "}
                  </strong>
                  {post?.servicesOffered?.toString()}
                </Typography>
                {/* Case Category */}
                <Typography
                  className={classes.messageText}
                  variant="body1"
                  component="p"
                  style={{ color: "#fff" }}
                >
                  <strong style={{ color: "#5096D1" }}>Case Category: </strong>
                  {post?.accessed?.toString()}
                </Typography>
                {/* How Did Client know about the helpline */}
                <Typography
                  className={classes.messageText}
                  variant="body1"
                  component="p"
                  style={{ color: "#fff" }}
                >
                  <strong style={{ color: "#5096D1" }}>
                    How did client know about the helpline?:{" "}
                  </strong>
                  {post?.howDidYouHear?.toString()}
                </Typography>
                <Typography
                  className={classes.messageText}
                  variant="body1"
                  component="p"
                  style={{ color: "#fff" }}
                >
                  <strong style={{ color: "#5096D1" }}>Remarks: </strong>
                  {post?.message}
                </Typography>

                {/* SESSIONS */}
                <Typography
                  className={classes.messageText}
                  variant="body1"
                  component="p"
                  style={{
                    color: "#fff",
                    textTransform: "uppercase",
                    // fontWeight: "bold",
                    // paddingBottom: "0px 0",
                  }}
                >
                  Number of Sessions ~ {post?.sessionList?.length}
                </Typography>
                {post?.sessionList?.map((item, index) => (
                  <div className={classes.root} key={item?._id}>
                    <Accordion
                      expanded={expanded === item?._id}
                      onChange={handleChange(item?._id)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          #.{index + 1}{" "}
                          {moment(item?.session_date).format("MMMM Do YYYY")}
                        </Typography>

                        <Typography
                          className={classes.secondaryHeading}
                          style={{ color: "#73AAE6" }}
                        >
                          {item.session.slice(0, 50).concat("...")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.secondaryHeading}>
                          {item.session}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </Card>
              {/* </div> */}
            </div>
          </Paper>
        </div>
        <AdminFooter />
        <div
          className="close-layer"
          onClick={() => document.documentElement.classList.toggle("nav-open")}
        />
      </div>
    </div>
  );
};

export default ClientDetailsCard;
