import React, { useState } from "react";
import { Button, Grid, Container } from "@material-ui/core";
import { Card, Col, Row, Spinner } from "react-bootstrap";
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useStyles from "./loginPageStyles";
import InputPasswordToggle from "./InputPasswordToggle";
// import { signup } from '../../actions/auth';
import { signin } from "actions/auth";

toast.configure();
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const Auth = () => {
  const {
    // auth,
    isLoading,
    // error
  } = useSelector((state) => state.auth);

  // console.log(error)

  const [form, setForm] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  // const switchMode = () => {
  //   setForm(initialState);
  //   setIsSignup((prevIsSignup) => !prevIsSignup);
  //   setShowPassword(false);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signin(form, history));
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (isSignup) {
  //     dispatch(signup(form, history));
  //   } else {
  //     dispatch(signin(form, history));
  //   }
  // };

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <>
      {isLoading ? (
        <Spinner
          animation="grow"
          size="lg"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <div
          className="full-page register-page section-image"
          data-color="blue"
          data-image={require("assets/img/bg5.jpg").default}
        >
          <div className="content d-flex align-items-center">
            <Container>
              <Card className="card-register card-plain text-center">
                <Card.Header>
                  <Row className="justify-content-center">
                    <Col md="8">
                      <div className="header-text">
                        <Card.Title as="h2">MHU DASHBOARD</Card.Title>
                        <Card.Subtitle as="h4">
                          Login to manage Clients
                        </Card.Subtitle>
                        {/* <hr></hr> */}
                      </div>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col className="mx-auto" md="5">
                      <form className={classes.form} onSubmit={handleSubmit}>
                        <i className="nc-icon nc-circle-09"></i>
                        <Card className="card-plain">
                          <div className="card-body">
                            <Grid container spacing={1}>
                              {isSignup && (
                                <>
                                  <InputPasswordToggle
                                    name="firstName"
                                    label="First Name"
                                    handleChange={handleChange}
                                    autoFocus
                                  />
                                  <InputPasswordToggle
                                    name="lastName"
                                    label="Last Name"
                                    handleChange={handleChange}
                                  />
                                </>
                              )}
                              <InputPasswordToggle
                                name="email"
                                label="Email Address"
                                handleChange={handleChange}
                                type="email"
                              />
                              <InputPasswordToggle
                                name="password"
                                label="Password"
                                handleChange={handleChange}
                                type={showPassword ? "text" : "password"}
                                handleShowPassword={handleShowPassword}
                              />
                              {isSignup && (
                                <InputPasswordToggle
                                  name="confirmPassword"
                                  label="Repeat Password"
                                  handleChange={handleChange}
                                  type="password"
                                />
                              )}
                            </Grid>
                          </div>
                        </Card>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="secondary"
                        >
                          {isSignup ? "Sign Up" : "Log On"}
                        </Button>
                      </form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </div>
          <div
            className="full-page-background"
            style={{
              backgroundImage:
                "url(" + require("assets/img/bg5.jpg").default + ")",
            }}
          ></div>
        </div>
      )}
    </>
  );
};

export default Auth;
