import React, { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper } from "@material-ui/core";

import useStyles from "./postsStyles.js";
import Post from "./Post/Post.js";
import { getPosts } from "../../../actions/posts.js";
import Paginate from "./Pagination.jsx";
import { useLocation } from "react-router";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ClientCard = () => {
  const { posts, isLoading } = useSelector((state) => state.posts);
  // console.log(posts)
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  const classes = useStyles();
  const query = useQuery();
  const dispatch = useDispatch();
  const page = query.get("page") || 1;

  useEffect(() => {
    dispatch(getPosts(user?.result?._id, user?.result?.user_role)); //Ordering is crucial. (userId, user_role)
    // console.log(user.result._id)
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner
          animation="grow"
          size="lg"
          variant="primary"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <Container fluid>
          <Card>
            <Card.Body>
              <Grid
                className={classes.container}
                container
                alignItems="stretch"
                spacing={3}
              >
                {posts?.map((post) => (
                  <Grid key={post?._id} item xs={12} sm={12} md={6} lg={3}>
                    <Post post={post} />
                  </Grid>
                ))}
              </Grid>
            </Card.Body>
          </Card>
        </Container>
      )}
      <Grid>
        <Paper
          style={{
            position: "absolute",
            top: "85%",
            left: "80%",
            padding: "16px",
            borderRadius: "20px",
            align: "right",
            verticalAlign: "top",
          }}
          elevation={6}
        >
          <Paginate page={page} user={user} />
        </Paper>
      </Grid>
    </>
  );
};

export default ClientCard;
