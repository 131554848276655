import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function AdminFooter() {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <ul className="footer-menu">
              <li>
                <a href="https://mhuhelpline.com/" 
                  style={{  fontSize: "13px", fontStyle: "italic", color: "#43BFFF" }}
                  target="_blank"
                >
                  XCALLY
                </a>
              </li>
              <li>
                <a href="https://mentalhealthuganda.org/" 
                  style={{  fontSize: "13px", fontStyle: "italic", color: "#43B" }}
                  target="_blank"
                >
                  MHU
                </a>
              </li>
            
              {/* <li>
                <a href="#pablo" 
                  onClick={(e) => e.preventDefault()}
                  // style={{ textDecoration: 'none' }}
                >
                  MHU
                </a>
              </li> */}
              {/* <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Portfolio
                </a>
              </li> */}
              {/* <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Blog
                </a>
              </li> */}
            </ul>
            <p className="copyright text-center">
              © <script>document.write(new Date().getFullYear())</script>
              <a href="https://mmict.it/" 
                style={{ textDecoration: 'none' }}
                target="_blank"
              >
                MM-iCT
              </a>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
