
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';



import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";

import App from "./App";

const store = createStore(reducers, {}, composeWithDevTools(applyMiddleware(thunk)));

    ReactDOM.render (
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
    
    );
    
    // <Route path="/auth" render={(props) => (user === null ? <AuthLayout {...props} /> : <Redirect to="auth/login-page/" />)} />


// const store = createStore(reducers, {}, composeWithDevTools(applyMiddleware(thunk)));

// ReactDOM.render(
//   <Provider store={store}>
//   <BrowserRouter>
//     <Switch>
//       <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
//       <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
//       <Redirect from="/" to="/admin/dashboard" />
//     </Switch>
//   </BrowserRouter>  
//   </Provider>,
//   document.getElementById("root")
// );
