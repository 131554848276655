import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { LOGOUT } from "constants/actionTypes.js";
import jwtDecode from "jwt-decode";
// import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";
import ClientDetailsCard from "./views/Forms/Posts/ClientDetailsCard.js";
import ClientEditForm from "./views/Forms/ClientEdit.js";
import CounselorPage from "views/Pages/CounselorPage.js";
const App = () => {
  const user = JSON.parse(localStorage.getItem("profile"));
  // console.log(user, "USERRRRRRRRRRRRR")

  const token = user?.token;
  //   console.log(token, "token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token === undefined) {
      dispatch({ type: LOGOUT });
      // console.log(token, "What is the token Now");
    } else {
      const decodedToken = jwtDecode(token);
      // console.log(decodedToken?.exp * 1000, "DecodedToken?.exp Now");
      if (Date.now() > decodedToken?.exp * 1000) {
        dispatch({ type: LOGOUT });
        // console.log(Date.now() > decodedToken?.exp * 1000, "Test?");
      }
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Switch>
        {/* <Route path="/auth" render={(props) => <AuthLayout {...props} />} /> */}

        <Route path="/cards/edit/:id" component={ClientEditForm} />
        <Route path="/cards/:id" component={ClientDetailsCard} />
        <Route path="/user-edit/:id" component={CounselorPage} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Redirect from="/" to="/admin/dashboard" />
        {!user && <Redirect to="/admin/auth/login-page" />}
      </Switch>
    </BrowserRouter>
  );
};

export default App;

// {user === null ? <Redirect from="/" to="/auth/login-page" /> : <Redirect from="/" to="/admin/dashboard" /> }
