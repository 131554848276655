export const districtOptions = [
  { value: "Abim", label: "Abim" },
  { value: "Adjumani", label: "Adjumani" },
  { value: "Agago", label: "Agago" },
  { value: "Alebtong", label: "Alebtong" },
  { value: "Amolatar", label: "Amolatar" },
  { value: "Amudat", label: "Amudat" },
  { value: "Amuria", label: "Amuria" },
  { value: "Amuru", label: "Amuru" },
  { value: "Apac", label: "Apac" },
  { value: "Arua", label: "Arua" },
  { value: "Arua", label: "Arua City" },
  { value: "Budaka", label: "Budaka" },
  { value: "Bududa", label: "Bududa" },
  { value: "Bugiri", label: "Bugiri" },
  { value: "Bugweri", label: "Bugweri" },
  { value: "Buhweju", label: "Buhweju" },
  { value: "Buikwe", label: "Buikwe" },
  { value: "Bukedea", label: "Bukedea" },
  { value: "Bukomansimbi", label: "Bukomansimbi" },
  { value: "Bukwo", label: "Bukwo" },
  { value: "Bulambuli", label: "Bulambuli" },
  { value: "Buliisa", label: "Buliisa" },
  { value: "Bundibugyo", label: "Bundibugyo" },
  { value: "Bunyangabu", label: "Bunyangabu" },
  { value: "Bushenyi", label: "Bushenyi" },
  { value: "Busia", label: "Busia" },
  { value: "Butaleja", label: "Butaleja" },
  { value: "Butambala", label: "Butambala" },
  { value: "Butebo", label: "Butebo" },
  { value: "Buvuma", label: "Buvuma" },
  { value: "Buyende", label: "Buyende" },
  { value: "Dokolo", label: "Dokolo" },
  { value: "Fort", label: "Fort Portal City" },
  { value: "Gomba", label: "Gomba" },
  { value: "Gulu", label: "Gulu" },
  { value: "Gulu", label: "Gulu City" },
  { value: "Hoima", label: "Hoima" },
  { value: "Hoima", label: "Hoima City" },
  { value: "Ibanda", label: "Ibanda" },
  { value: "Iganga", label: "Iganga" },
  { value: "Isingiro", label: "Isingiro" },
  { value: "Jinja", label: "Jinja" },
  { value: "Jinja", label: "Jinja City" },
  { value: "Kaabong", label: "Kaabong" },
  { value: "Kabale", label: "Kabale" },
  { value: "Kabarole", label: "Kabarole" },
  { value: "Kaberamaido", label: "Kaberamaido" },
  { value: "Kagadi", label: "Kagadi" },
  { value: "Kakumiro", label: "Kakumiro" },
  { value: "Kalaki", label: "Kalaki" },
  { value: "Kalangala", label: "Kalangala" },
  { value: "Kaliro", label: "Kaliro" },
  { value: "Kalungu", label: "Kalungu" },
  { value: "Kampala", label: "Kampala" },
  { value: "Kamuli", label: "Kamuli" },
  { value: "Kamwenge", label: "Kamwenge" },
  { value: "Kanungu", label: "Kanungu" },
  { value: "Kapchorwa", label: "Kapchorwa" },
  { value: "Kapelabyong", label: "Kapelabyong" },
  { value: "Karenga", label: "Karenga" },
  { value: "Kasese", label: "Kasese" },
  { value: "Kassanda", label: "Kassanda" },
  { value: "Katakwi", label: "Katakwi" },
  { value: "Kayunga", label: "Kayunga" },
  { value: "Kazo", label: "Kazo" },
  { value: "Kibale", label: "Kibale" },
  { value: "Kiboga", label: "Kiboga" },
  { value: "Kibuku", label: "Kibuku" },
  { value: "Kikuube", label: "Kikuube" },
  { value: "Kiruhura", label: "Kiruhura" },
  { value: "Kiryandongo", label: "Kiryandongo" },
  { value: "Kisoro", label: "Kisoro" },
  { value: "Kitagwenda", label: "Kitagwenda" },
  { value: "Kitgum", label: "Kitgum" },
  { value: "Koboko", label: "Koboko" },
  { value: "Kole", label: "Kole" },
  { value: "Kotido", label: "Kotido" },
  { value: "Kumi", label: "Kumi" },
  { value: "Kwania", label: "Kwania" },
  { value: "Kween", label: "Kween" },
  { value: "Kyankwanzi", label: "Kyankwanzi" },
  { value: "Kyegegwa", label: "Kyegegwa" },
  { value: "Kyenjojo", label: "Kyenjojo" },
  { value: "Kyotera", label: "Kyotera" },
  { value: "Lamwo", label: "Lamwo" },
  { value: "Lira", label: "Lira" },
  { value: "Lira", label: "Lira City" },
  { value: "Luuka", label: "Luuka" },
  { value: "Luweero", label: "Luweero" },
  { value: "Lwengo", label: "Lwengo" },
  { value: "Lyantonde", label: "Lyantonde" },
  { value: "Madi", label: "Madi-Okollo" },
  { value: "Manafwa", label: "Manafwa" },
  { value: "Maracha", label: "Maracha" },
  { value: "Masaka", label: "Masaka" },
  { value: "Masaka", label: "Masaka City" },
  { value: "Masindi", label: "Masindi" },
  { value: "Mayuge", label: "Mayuge" },
  { value: "Mbale", label: "Mbale" },
  { value: "Mbale", label: "Mbale City" },
  { value: "Mbarara", label: "Mbarara" },
  { value: "Mbarara", label: "Mbarara City" },
  { value: "Mitooma", label: "Mitooma" },
  { value: "Mityana", label: "Mityana" },
  { value: "Moroto", label: "Moroto" },
  { value: "Moyo", label: "Moyo" },
  { value: "Mpigi", label: "Mpigi" },
  { value: "Mubende", label: "Mubende" },
  { value: "Mukono", label: "Mukono" },
  { value: "Nabilatuk", label: "Nabilatuk" },
  { value: "Nakapiripirit", label: "Nakapiripirit" },
  { value: "Nakaseke", label: "Nakaseke" },
  { value: "Nakasongola", label: "Nakasongola" },
  { value: "Namayingo", label: "Namayingo" },
  { value: "Namisindwa", label: "Namisindwa" },
  { value: "Namutumba", label: "Namutumba" },
  { value: "Napak", label: "Napak" },
  { value: "Nebbi", label: "Nebbi" },
  { value: "Ngora", label: "Ngora" },
  { value: "Ntoroko", label: "Ntoroko" },
  { value: "Ntungamo", label: "Ntungamo" },
  { value: "Nwoya", label: "Nwoya" },
  { value: "Obongi", label: "Obongi" },
  { value: "Omoro", label: "Omoro" },
  { value: "Otuke", label: "Otuke" },
  { value: "Oyam", label: "Oyam" },
  { value: "Pader", label: "Pader" },
  { value: "Pakwach", label: "Pakwach" },
  { value: "Pallisa", label: "Pallisa" },
  { value: "Rakai", label: "Rakai" },
  { value: "Rubanda", label: "Rubanda" },
  { value: "Rubirizi", label: "Rubirizi" },
  { value: "Rukiga", label: "Rukiga" },
  { value: "Rukungiri", label: "Rukungiri" },
  { value: "Rwampara", label: "Rwampara" },
  { value: "Serere", label: "Serere" },
  { value: "Sheema", label: "Sheema" },
  { value: "Sironko", label: "Sironko" },
  { value: "Soroti", label: "Soroti" },
  { value: "Soroti", label: "Soroti City" },
  { value: "Ssembabule", label: "Ssembabule" },
  { value: "Terego", label: "Terego" },
  { value: "Tororo", label: "Tororo" },
  { value: "Wakiso", label: "Wakiso" },
  { value: "Yumbe", label: "Yumbe" },
  { value: "Zombo", label: "Zombo" },
];

export default {
  districtOptions,
};
