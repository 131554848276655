import React, { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUser, profileUpdate } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";

import { createUser } from "api";

const CounselorPage = () => {
  const auth = useSelector((state) => state.auth);
  const { counselor, isLoading } = auth;
  // console.log(counselor?._id, "COUNSELOR'S OBJECT ID BEING EDITED") //Renders when navigation is from Counselors/Edit User
  // console.log(counselor)
  // const {auth : authUser} = auth
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  // console.log(user?.result?._id, "USER RESULT ID - Logged in User Session")
  // console.log(user.result._id) //726 Works when navigation is from Counselor/Edit Profile Screen.
  // const authUser = "";
  // const { auth } = allUsers
  // const [authUser, setAuthUser] = useState("")

  // if (user.result.user_role == "ADMIN") {
  //     setAuthUser(auth)
  // } else {
  //     // authUser = user
  //     setAuthUser(user)
  // }

  const [postData, setPostData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    user_role: "",
  });

  // useEffect(async () => {
  //     if(counselor._id) {
  //         // console.log(counselor)
  //         await setPostData(counselor)
  //     } else {
  //         clear();
  //         // history.push('/admin/user-form')
  //     }
  // }, []);

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     await counselor._id
  //     // const data = await dispatch(getUser())
  //     // const data = await getData();
  //     setPostData(counselor);
  //   }

  //   fetchUser();
  // }, []);

  // useEffect(() => {
  //     if (!counselor._id)
  //     setPostData(counselor);
  // }, [counselor._id])

  // console.log(auth)
  // console.log(user.result.email)

  const dispatch = useDispatch();
  const history = useHistory();

  const clear = () => {
    setPostData({
      email: "",
      password: "",
      confirmPassword: "",
      user_role: "",
    });
  };

  //   const { auth, isLoading, error } = useSelector((state) => state.auth)
  //   console.log(auth._id)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!counselor._id) {
      dispatch(createUser(history.push("/admin/user-form")));
    } else {
      // console.log({...postData})
      const { password, confirmPassword } = postData;
      if (password !== confirmPassword) {
        toast.error("Passwords don't match!");
      } else {
        dispatch(
          profileUpdate(
            counselor._id,
            { ...postData, name: user?.result?.name },
            user?.result?._id,
            user?.result?.user_role,
            history.push("/admin/users")
          )
        );
        clear();
      }
    }
  };

  const handleChange = async (e) => {
    setPostData({ ...postData, [e.target.name]: e.target.value });
  };
  return isLoading ? (
    <Spinner
      animation="grow"
      variant="danger"
      style={{ marginTop: "70px", top: "50%", left: "50%", position: "fixed" }}
    />
  ) : (
    <>
      <Container fluid>
        <div className="section-image" data-image="../../assets/img/bg5.jpg">
          {/* you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " */}
          <Container>
            <Row>
              <Col md="8" sm="6">
                <Form
                  action=""
                  className="form"
                  method=""
                  onSubmit={handleSubmit}
                >
                  <Card>
                    <Card.Header>
                      <Card.Header>
                        <Card.Title as="h4">Edit Profile</Card.Title>
                      </Card.Header>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="5">
                          <Form.Group>
                            <label>Organization </label>
                            <Form.Control
                              defaultValue="Mental Health Uganda."
                              disabled
                              placeholder="Company"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>User Names</label>
                            <Form.Control
                              defaultValue={counselor?.name}
                              disabled
                              //   placeholder="Company"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Email Address</label>
                            <Form.Control
                              defaultValue={counselor?.email}
                              disabled
                              //   placeholder="Last Name"
                              autoComplete="off"
                              type="email"
                              name="email"
                              onChange={handleChange}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row></Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Password Reset</label>
                            <Form.Control
                              //   defaultValue="Mike"
                              placeholder="Enter Password"
                              type="password"
                              name="password"
                              onChange={handleChange}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Confirm Password</label>
                            <Form.Control
                              //   defaultValue="Mike"
                              placeholder="Confirm Password"
                              type="password"
                              name="confirmPassword"
                              onChange={handleChange}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        {/* <Col className="px-1" md="6">
                          <Form.Group>
                            <label>Confirm Password</label>
                            <Form.Control
                            //   defaultValue="Andrew"
                              placeholder="Repeat Password"
                              type="password"
                              name="confirmPassword"
                              handleChange={handleChange}
                            ></Form.Control>
                          </Form.Group>
                        </Col> */}
                      </Row>
                      {/* <Row>
                        <Col md="12" className="mb-2">
                          <Form.Group>
                            <label>About Me</label>
                            <Form.Control
                              cols="80"
                              defaultValue="Professional fun facts..."
                              placeholder="Here can be your description"
                              rows="4"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row> */}
                      <Row>
                        <label>
                          Assign Role <span className="star">*</span>
                        </label>
                        <Select
                          className="basic-multi-select"
                          classNamePrefix="react-select"
                          name="user_role"
                          // value={postData.value}
                          defaultValue={{
                            value: counselor?.user_role,
                            label: counselor?.user_role,
                          }}
                          onChange={(value) =>
                            setPostData({ ...postData, user_role: value.value })
                          }
                          options={[
                            // {
                            //   value: "",
                            //   label: "User Role",
                            //   isDisabled: true,
                            // },
                            { value: "ADMIN", label: "ADMIN" },
                            { value: "SUPERVISOR", label: "SUPERVISOR" },
                            { value: "CREATOR", label: "CREATOR" },
                          ]}
                          placeholder="Select Role"
                        />
                      </Row>
                      <Button
                        className="btn-fill pull-right mt-3"
                        type="submit"
                        variant="info"
                      >
                        Update Profile
                      </Button>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
              <Col md="4">
                <Card className="card-user">
                  <Card.Header className="no-padding">
                    <div className="card-image">
                      <img
                        alt="..."
                        src={
                          require("assets/img/full-screen-image-3.jpg").default
                        }
                      ></img>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="author">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <Avatar
                          className="avatar border-gray mx-auto"
                          alt={counselor?.email}
                          src={""}
                        >
                          {counselor?.name?.charAt[0] || ""}
                          {counselor?.name?.charAt(0) || ""}
                        </Avatar>
                        <Card.Title
                          as="h5"
                          style={{ textTransform: "capitalize" }}
                        >
                          {counselor?.name}
                        </Card.Title>
                      </a>
                      <p className="card-description">
                        Role: {counselor?.user_role}
                      </p>
                    </div>
                    <div className="card-description text-center">
                      User ID: <br></br>
                      <p style={{ fontStyle: "italic", color: "#43BFFF" }}>
                        {counselor?._id}
                      </p>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="button-container text-center">
                      <Button
                        className="btn-simple btn-icon"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        variant="link"
                      >
                        <i className="fab fa-facebook-square"></i>
                      </Button>
                      <Button
                        className="btn-simple btn-icon"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        variant="link"
                      >
                        <i className="fab fa-twitter"></i>
                      </Button>
                      <Button
                        className="btn-simple btn-icon"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        variant="link"
                      >
                        <i className="fab fa-google-plus-square"></i>
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
};

export default CounselorPage;
