import axios from "axios";

const API = axios.create({ baseURL: "https://mhu-data-server.herokuapp.com/" });
// const API = axios.create({ baseURL: "http://localhost:8000" });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }

  return req;
});

export const fetchPost = (id, userId, role) =>
  API.get(`/posts/${id}/${userId}/${role}`);
export const fetchPosts = (userId, role, page) =>
  API.get(`/posts/${userId}/${role}?page=${page}`);
export const fetchPostsNoLimit = (userId, role, page) =>
  API.get(`/posts/all/${userId}/${role}?page=${page}`);

export const fetchAllPosts = (userId, role) =>
  API.get(`/posts/allPosts/${userId}/${role}`);

// export const fetchPostsBySearch = (searchQuery) =>
//   API.get(`/posts/search?searchQuery=${searchQuery.search || "none"}`);

export const fetchClientsBySearch = (searchQuery) =>
  API.get(
    `/posts/search/${searchQuery.user_id}?searchQuery=${
      searchQuery.search || "none"
    }`
  );

export const createPost = (newPost, userId, role) =>
  API.post(`/posts/${userId}/${role}`, newPost);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const fetchUpdatePost = (id, updatedPost, userId, role) =>
  API.put(`/posts/${id}/${userId}/${role}`, updatedPost);
export const deletePost = (id, userId, role) =>
  API.delete(`/posts/${id}/${userId}/${role}`);

export const signIn = (formData) => API.post("/user/signin", formData);
export const signUp = (formData) => API.post("/user/signup", formData);
export const createUser = (formData, userId, role) =>
  API.post(`/user/${userId}/${role}`, formData);
export const fetchUsers = (userId, role) =>
  API.get(`/user/all/${userId}/${role}`);
export const fetchUser = (id, userId, role) =>
  API.get(`/user/${id}/${userId}/${role}`);
export const profileUpdate = (
  resourceUserId,
  updatedUser,
  loggedInUserId,
  role
) =>
  API.patch(`/user/${resourceUserId}/${loggedInUserId}/${role}`, updatedUser);

export const deleteUser = (id, userId, role) =>
  API.delete(`/user/${id}/${userId}/${role}`);

// Get User stats
export const getUserStatsApi = () => API.get(`/posts/userStats`);
