import React, { useEffect, useState } from "react";
// import { CircularProgress, Button } from '@material-ui/core';


// react-bootstrap components
import { Button, Card, Container, Row, Col, Spinner, } from "react-bootstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { useDispatch, useSelector } from "react-redux";
import { getPostsNoLimit } from "../../actions/posts";

function ReactTables() {
  const { posts, isLoading} = useSelector((state) => state.posts);
  // console.log(posts)

  const [user] = useState(JSON.parse(localStorage.getItem('profile')));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPostsNoLimit(user?.result?._id, user?.result?.user_role)) // result._id => userId & result.user_role => User role
}, []);

  const [data, setData] = useState(
    posts.map((prop, key) => {
      return {
        id: key,
        title: prop.callerName,
        sex: prop.callerSex,
        mobile: prop.mobile,
        language: prop.language,
        nationality: prop.nationality,
        age: prop.callerAge,
        age: prop.difficulty,
        age: prop.createdAt,
        age: prop._id,
        // age: prop[3],
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o.id === key);
                alert(
                  "You've clicked LIKE button on \n{ \nName: " +
                    obj.callerName +
                    ", \ncallerName: " +
                    obj.callerSex +
                    ", \ncallerSex: " +
                    obj.mobile +
                    ", \nmobile: " +
                    obj.language +
                    ",\nlanguage: " +
                    obj.callerAge +
                    "\n}."
                );
              }}
              variant="info"
              size="sm"
              className="text-info btn-link like"
            >
              <i className="fa fa-heart" />
            </Button>{" "}
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o.id === key);
                alert(
                  "You've clicked EDIT button on \n{ \nName: " +
                    obj.callerName +
                    ", \ncallerName: " +
                    obj.callerSex +
                    ", \ncallerSex: " +
                    obj.mobile +
                    ", \nmobile: " +
                    obj.language +
                    ", \nlanguage: " +
                    obj.callerAge +
                    "\n}."
                );
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                var newData = data;
                newData.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    newData.splice(i, 1);
                    return true;
                  }
                  return false;
                });
                setData([...newData]);
              }}
              variant="danger"
              size="sm"
              className="btn-link remove text-danger"
            >
              <i className="fa fa-times" />
            </Button>{" "}
          </div>
        ),
      };
    })
  );

  if (isLoading) {
    return (
        <div style={{ marginTop: '70px', top: '50%',left: '50%', position: 'fixed',}}>
            <Spinner animation="grow" size="lg" variant="primary"  />
        </div>
    )
}
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Clients Table</h4>
            <p className="category">
              A List View of all Active Mental Health Clients 
            </p>
            <Card>
              <Card.Body>
                <ReactTable
                  data={posts}
                  columns={[
                    {
                      Header: "Name",
                      accessor: "callerName",
                    },
                    {
                      Header: "Sex",
                      accessor: "callerSex",
                    },
                    {
                      Header: "Mobile",
                      accessor: "mobile",
                    },
                    {
                      Header: "Language",
                      accessor: "language",
                    },
                    {
                      Header: "Nationality",
                      accessor: "nationality",
                    },
                    {
                      Header: "Age",
                      accessor: "callerAge",
                    },
                    {
                      Header: "Difficulty",
                      accessor: "difficulty",
                    },
                    {
                      Header: "Walkin-Date",
                      accessor: "createdAt",
                    },
                    {
                      Header: "ID",
                      accessor: "_id",
                    },
                    {
                      // Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  /*
                    You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                  */
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ReactTables;
