// import * as actionType from '../constants/actionTypes.js';

import {
  LOGOUT,
  AUTH,
  FETCH_USERS,
  START_LOADING,
  END_LOADING,
  USER_LOGIN_FAIL,
  AGENT_CREATION_FAIL,
  CREATE,
  CREATE_USER,
  DELETE_USER,
  UPDATE_USER,
  FETCH_USER_SINGLE,
} from "../constants/actionTypes.js";

const authReducer = (state = { isLoading: false, auth: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case AUTH:
      // console.log(action?.data)
      localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
      return { ...state, auth: action?.data };
    case USER_LOGIN_FAIL:
      // console.log(payload)
      return { error: action.payload };
    case CREATE_USER:
      return { ...state, auth: [...state.auth, action.payload] };
    case FETCH_USERS:
      return { ...state, auth: action.payload.data };
    case FETCH_USER_SINGLE:
      return { ...state, counselor: action?.payload?.counselor }; //
    case AGENT_CREATION_FAIL:
      return { error: action.payload };
    case UPDATE_USER:
      return {
        ...state,
        auth: state.auth?.map((user) =>
          user._id === action.payload._id ? action.payload : user
        ),
      };
    // return { ...state, auth: [...state.auth, action.payload ] }
    case DELETE_USER:
      return {
        ...state,
        auth: state.auth.filter(
          (counselor) => counselor._id !== action.payload
        ),
      };
    case LOGOUT:
      localStorage.clear();
      return { ...state, auth: [] };

    default:
      return state;
  }
};

export default authReducer;

// import * as actionType from 'constants/actionTypes';

// const authReducer = (state = { authData: null }, action) => {
//   switch (action.type) {
//     case actionType.AUTH:
//       localStorage.setItem('profile', JSON.stringify({ ...action?.data }));

//       return { ...state, authData: action.data, loading: false, errors: null };
//     case actionType.LOGOUT:
//       localStorage.clear();

//       return { ...state, authData: null, loading: false, errors: null };
//     default:
//       return state;
//   }
// };

// export default authReducer;
