import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import {
  Button,
  Card,
  Spinner,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PregnantWomanOutlinedIcon from "@material-ui/icons/PregnantWomanOutlined";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import { getAllPosts, getUserStats, getPostsNoLimit } from "../actions/posts";

// Loading component for consistent loading UI
const LoadingIndicator = ({ text = "Processing..." }) => (
  <div className="d-flex align-items-center justify-content-center p-3">
    <Spinner animation="border" variant="primary" size="sm" className="mr-2" />
    <span className="text-primary">{text}</span>
  </div>
);

const LoadingCard = ({ title }) => (
  <Card className="card-stats">
    <Card.Body>
      <LoadingIndicator text={`Computing ${title}...`} />
    </Card.Body>
    <Card.Footer>
      <hr />
      <div className="stats">
        <i className="fas fa-spinner fa-spin mr-1"></i>
        Fetching data...
      </div>
    </Card.Footer>
  </Card>
);

const Dashboard = () => {
  const {
    postsData = [],
    isLoading,
    userStats = [],
  } = useSelector((state) => state.posts);
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  const [metricsLoaded, setMetricsLoaded] = useState(false);
  const [statsLoaded, setStatsLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      if (user?.result?._id && user?.result?.user_role) {
        setMetricsLoaded(false);
        setStatsLoaded(false);

        try {
          await Promise.all([
            dispatch(getAllPosts(user.result._id, user.result.user_role)),
            dispatch(getUserStats()),
          ]);

          setMetricsLoaded(true);
          setStatsLoaded(true);
        } catch (error) {
          console.error("Error loading dashboard data:", error);
        }
      }
    };

    loadData();
  }, [dispatch, user]);

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  // Data processing functions
  const processGenderData = () => {
    const males = postsData.filter((element) => element.callerSex === "male");
    const females = postsData.filter(
      (element) => element.callerSex === "female"
    );
    const undisclosed = postsData.filter(
      (element) => element.callerSex === "undisclosed"
    );
    return { males, females, undisclosed };
  };

  const processRegionalData = () => {
    const central = postsData.filter((element) => element.region === "Central");
    const eastern = postsData.filter((element) => element.region === "Eastern");
    const northern = postsData.filter(
      (element) => element.region === "Northern"
    );
    const western = postsData.filter((element) => element.region === "Western");

    const sumOfRegions =
      central.length + eastern.length + northern.length + western.length;

    return {
      central: {
        count: central.length,
        percentage: addDecimals((central.length / sumOfRegions) * 100),
      },
      eastern: {
        count: eastern.length,
        percentage: addDecimals((eastern.length / sumOfRegions) * 100),
      },
      northern: {
        count: northern.length,
        percentage: addDecimals((northern.length / sumOfRegions) * 100),
      },
      western: {
        count: western.length,
        percentage: addDecimals((western.length / sumOfRegions) * 100),
      },
    };
  };

  // ASSESSMENT calculations
  const mhTreatment = postsData.filter((element) =>
    element.accessed?.includes("Seeking Mental Health Treatment")
  );
  const skPsychoSoc = postsData.filter((element) =>
    element.accessed?.includes("Psychosocial Support")
  );
  const rehab = postsData.filter((element) =>
    element.accessed?.includes("Rehabilitation")
  );
  const legal = postsData.filter((element) =>
    element.accessed?.includes("Legal aid")
  );
  const safeProtection = postsData.filter((element) =>
    element.accessed?.includes("Safety & Protection")
  );
  const livelihood = postsData.filter((element) =>
    element.accessed?.includes("Livelihood/economic")
  );
  const schoolFees = postsData.filter((element) =>
    element.accessed?.includes("School Fees")
  );
  const information = postsData.filter((element) =>
    element.accessed?.includes("Seeking Information")
  );
  const peerSupport = postsData.filter((element) =>
    element.accessed?.includes("Peer Support")
  );
  const tdrMedicine = postsData.filter((element) =>
    element.accessed?.includes("Traditional Medicine / Spiritual Healing")
  );
  const prayer = postsData.filter((element) =>
    element.accessed?.includes("Prayer")
  );
  const others = postsData.filter((element) =>
    element.accessed?.includes("Others")
  );

  const lengthAllAssessments = [
    mhTreatment.length,
    skPsychoSoc.length,
    rehab.length,
    legal.length,
    safeProtection.length,
    livelihood.length,
    schoolFees.length,
    information.length,
    peerSupport.length,
    tdrMedicine.length,
    prayer.length,
    others.length,
  ];

  const sumOfAssessments = lengthAllAssessments.reduce(
    (acc, curr) => acc + curr,
    0
  );

  // Only calculate percentages if we have a non-zero sum
  const calculatePercentage = (count) => {
    if (sumOfAssessments === 0) return 0;
    return (count / sumOfAssessments) * 100;
  };

  // Calculate percentages

  const mhTreatmentPercentage = addDecimals(
    calculatePercentage(mhTreatment.length)
  );
  const skPsychoSocPercentage = addDecimals(
    calculatePercentage(skPsychoSoc.length)
  );
  const rehabPercentage = addDecimals(calculatePercentage(rehab.length));
  const legalPercentage = addDecimals(calculatePercentage(legal.length));
  const safeProtectionPercentage = addDecimals(
    calculatePercentage(safeProtection.length)
  );
  const livelihoodPercentage = addDecimals(
    calculatePercentage(livelihood.length)
  );
  const schoolFeesPercentage = addDecimals(
    calculatePercentage(schoolFees.length)
  );
  const informationPercentage = addDecimals(
    calculatePercentage(information.length)
  );
  const peerSupportPercentage = addDecimals(
    calculatePercentage(peerSupport.length)
  );
  const tdrMedicinePercentage = addDecimals(
    calculatePercentage(tdrMedicine.length)
  );
  const prayerPercentage = addDecimals(calculatePercentage(prayer.length));
  const othersPercentage = addDecimals(calculatePercentage(others.length));

  const processCaseCategories = () => {
    // First check if postsData exists and has items
    if (!postsData || postsData.length === 0) {
      return {};
    }

    const categories = {
      "Mental Health Care": postsData.filter((element) =>
        element.accessed?.includes("Seeking Mental Health Treatment")
      ),
      "Psychosocial Support": postsData.filter((element) =>
        element.accessed?.includes("Psychosocial Support")
      ),
      Rehabilitation: postsData.filter((element) =>
        element.accessed?.includes("Rehabilitation")
      ),
      "Legal Aid": postsData.filter((element) =>
        element.accessed?.includes("Legal aid")
      ),
      "Safety & Protection": postsData.filter((element) =>
        element.accessed?.includes("Safety & Protection")
      ),
      Livelihood: postsData.filter((element) =>
        element.accessed?.includes("Livelihood/economic")
      ),
      "School Fees": postsData.filter((element) =>
        element.accessed?.includes("School Fees")
      ),
      Information: postsData.filter((element) =>
        element.accessed?.includes("Seeking Information")
      ),
      "Peer Support": postsData.filter((element) =>
        element.accessed?.includes("Peer Support")
      ),
      "Traditional Medicine": postsData.filter((element) =>
        element.accessed?.includes("Traditional Medicine / Spiritual Healing")
      ),
      Prayer: postsData.filter((element) =>
        element.accessed?.includes("Prayer")
      ),
      Others: postsData.filter((element) =>
        element.accessed?.includes("Others")
      ),
    };

    // Calculate total considering that one post might have multiple categories
    let total = 0;
    Object.values(categories).forEach((categoryPosts) => {
      total += categoryPosts.length;
    });

    // Convert counts to percentages
    const result = {};
    Object.entries(categories).forEach(([key, value]) => {
      result[key] = {
        count: value.length,
        percentage:
          total > 0 ? ((value.length / total) * 100).toFixed(2) : "0.00",
      };
    });

    return result;
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="text-center">
          <Spinner
            animation="border"
            variant="primary"
            style={{ width: "3rem", height: "3rem" }}
          />
          <p className="mt-2">Dashboard Loading...</p>
        </div>
      </div>
    );
  }

  const genderData = processGenderData();
  const regionalData = processRegionalData();
  const caseCategories = processCaseCategories();

  const genderCards = [
    {
      title: "Active Clients",
      count: postsData.length,
      icon: "nc-refresh-02",
      variant: "info",
    },
    {
      title: "Male",
      count: genderData.males.length,
      icon: <AccessibilityIcon />,
      variant: "success",
    },
    {
      title: "Female",
      count: genderData.females.length,
      icon: <PregnantWomanOutlinedIcon />,
      variant: "danger",
    },
    {
      title: "Undisclosed",
      count: genderData.undisclosed.length,
      icon: "nc-simple-remove",
      variant: "primary",
    },
  ];

  const ChartLegend = ({ categories, colors }) => (
    <div className="grid grid-cols-2 gap-2 mt-4 text-sm">
      {Object.entries(categories).map(([key, value], index) => (
        <div key={key} className="flex items-center">
          <span
            className="w-3 h-3 rounded-full mr-2 inline-block"
            style={{ backgroundColor: colors[index % colors.length] }}
          />
          <span>
            {key} ({value.percentage}%)
          </span>
        </div>
      ))}
    </div>
  );

  return (
    <Container fluid>
      {/* Summary Cards Section */}
      <Row>
        {genderCards.map((card, index) => (
          <Col lg="3" sm="6" key={card.title}>
            {!metricsLoaded ? (
              <LoadingCard title={card.title.toLowerCase()} />
            ) : (
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i
                          className={`nc-icon ${
                            typeof card.icon === "string" ? card.icon : ""
                          } text-${card.variant}`}
                        >
                          {typeof card.icon !== "string" && card.icon}
                        </i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">{card.title}</p>
                        <Card.Title as="h4">
                          {card.count.toLocaleString()}
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr />
                  <div className="stats">
                    <i className="far fa-calendar-alt mr-1"></i>
                    {index === 0 ? (
                      <Button
                        size="sm"
                        className="btn-outline"
                        onClick={() =>
                          dispatch(
                            getPostsNoLimit(
                              user?.result?._id,
                              user?.result?.user_role
                            )
                          )
                        }
                      >
                        <i className="fas fa-redo mr-1"></i>
                        Update Now
                      </Button>
                    ) : (
                      `Total ${card.title.toLowerCase()} clients`
                    )}
                  </div>
                </Card.Footer>
              </Card>
            )}
          </Col>
        ))}
      </Row>

      {/* Regional Stats Section */}
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Contact Center Stats</Card.Title>
              <p className="card-category">Calls by region</p>
            </Card.Header>
            <Card.Body>
              {!metricsLoaded ? (
                <div className="stats">
                  <i className="fas fa-spinner fa-spin mr-1"></i>
                  Fetching regional statistics...
                  {/* <LoadingIndicator text="Fetching regional statistics..." /> */}
                </div>
              ) : (
                <Row>
                  <Col md="6">
                    <Table responsive>
                      <tbody>
                        {Object.entries(regionalData).map(([region, data]) => (
                          <tr key={region}>
                            <td>
                              <div className="flag">
                                <i
                                  className={`fas fa-circle mr-1 text-${
                                    region === "central"
                                      ? "info"
                                      : region === "eastern"
                                      ? "default"
                                      : region === "northern"
                                      ? "danger"
                                      : "success"
                                  }`}
                                ></i>
                              </div>
                            </td>
                            <td>{region.toUpperCase()}</td>
                            <td className="text-right">
                              {data.count.toLocaleString()}
                            </td>
                            <td className="text-right">{data.percentage}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Case Categories Section */}
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Case Category</Card.Title>
              <p className="card-category">
                Based on Counselors' assessment while interacting with Clients
              </p>
            </Card.Header>
            <Card.Body>
              {!metricsLoaded ? (
                <LoadingIndicator text="Fetching case categories..." />
              ) : (
                <Row>
                  <Col md="5" className="mx-md-auto">
                    <div className="relative">
                      <ChartistGraph
                        className="ct-perfect-fourth"
                        data={{
                          labels: Object.keys(caseCategories),
                          series: Object.values(caseCategories)
                            .map((category) => parseFloat(category.percentage))
                            .filter((value) => !isNaN(value)),
                        }}
                        type="Pie"
                        options={{
                          donut: true,
                          donutWidth: 60,
                          startAngle: 270,
                          showLabel: true,
                          chartPadding: 20,
                          labelOffset: 20,
                          labelInterpolationFnc: (value, index) => {
                            const percentage =
                              Object.values(caseCategories)[index].percentage;
                            return percentage > 5 ? `${percentage}%` : "";
                          },
                        }}
                      />
                    </div>
                    <style>
                      {`
                            .ct-perfect-fourth {
                              position: relative;
                              height: 300px;
                            }

                            /* Custom colors for the chart segments */
                            .ct-series-a .ct-slice-donut { stroke: #2EC3E6; }
                            .ct-series-b .ct-slice-donut { stroke: #EF434E; }
                            .ct-series-c .ct-slice-donut { stroke: #FAA436; }
                            .ct-series-d .ct-slice-donut { stroke: #8069AE; }
                            .ct-series-e .ct-slice-donut { stroke: #88C540; }
                            .ct-series-f .ct-slice-donut { stroke: #5E5F5E; }
                            .ct-series-g .ct-slice-donut { stroke: #3575BB; }
                            .ct-series-h .ct-slice-donut { stroke: #DD4B38; }
                            .ct-series-i .ct-slice-donut { stroke: #36465C; }
                            .ct-series-j .ct-slice-donut { stroke: #E42E28; }
                            .ct-series-k .ct-slice-donut { stroke: #5FA9DD; }
                            .ct-series-l .ct-slice-donut { stroke: #5E5F5E; }

                            .ct-label {
                              font-size: 0.85rem;
                              font-weight: 600;
                              fill: #000;
                            }
                          `}
                    </style>
                  </Col>
                  <Col md="9">
                    <Card.Body>
                      <Row>
                        <Col md="6">
                          <Table responsive>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#2EC3E6" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Mental Health Care (mh)</td>
                                <td className="text-right">
                                  {mhTreatment.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {mhTreatmentPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#EF434E" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Psychosocial Support (ps)</td>
                                <td className="text-right">
                                  {skPsychoSoc.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {skPsychoSocPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#FAA436" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Rehabilitation (rh)</td>
                                <td className="text-right">
                                  {rehab.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {rehabPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#8069AE" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Legal Services (ls)</td>
                                <td className="text-right">
                                  {legal.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {legalPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#88C540" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Safety & Protection (sp)</td>
                                <td className="text-right">
                                  {safeProtection.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {safeProtectionPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#5E5F5E" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>School Fees (sf)</td>
                                <td className="text-right">
                                  {schoolFees.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {schoolFeesPercentage}%
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col md="6">
                          <Table responsive>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#3575BB" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Livelihood (lv)</td>
                                <td className="text-right">
                                  {livelihood.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {livelihoodPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#5E5F5E" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Information (if)</td>
                                <td className="text-right">
                                  {information.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {informationPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#DD4B38" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Peer Support (pe)</td>
                                <td className="text-right">
                                  {peerSupport.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {peerSupportPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#36465C" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Traditional Medicine (tm)</td>
                                <td className="text-right">
                                  {tdrMedicine.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {tdrMedicinePercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#E42E28" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Prayer (pr)</td>
                                <td className="text-right">
                                  {prayer.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {prayerPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#5FA9DD" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Others (ot)</td>
                                <td className="text-right">
                                  {others.length.toLocaleString()}
                                </td>
                                <td className="text-right">
                                  {othersPercentage}%
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col md="6">
                          <Table responsive>
                            <tbody>{/* Second column of categories */}</tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Col>
                </Row>
              )}
            </Card.Body>
            <Card.Footer>
              <div className="legend">
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#2EC3E6" }}
                ></i>
                mh {mhTreatmentPercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#EF434E" }}
                ></i>
                ps {skPsychoSocPercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#FAA436" }}
                ></i>
                rh {rehabPercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#8069AE" }}
                ></i>
                ls {legalPercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#88C540" }}
                ></i>
                sp {safeProtectionPercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#5E5F5E" }}
                ></i>
                sf {schoolFeesPercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#3575BB" }}
                ></i>
                lv {livelihoodPercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#5E5F5E" }}
                ></i>
                if {informationPercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#DD4B38" }}
                ></i>
                pe {peerSupportPercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#36465C" }}
                ></i>
                tm {tdrMedicinePercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#E42E28" }}
                ></i>
                pr {prayerPercentage}% ||
                <i
                  className="fas fa-circle mr-1"
                  style={{ color: "#5FA9DD" }}
                ></i>
                ot {othersPercentage}%
              </div>
              <hr />
              <div className="stats">
                <i
                  className="fas fa-redo mr-1"
                  onClick={() =>
                    dispatch(
                      getPostsNoLimit(
                        user?.result?._id,
                        user?.result?.user_role
                      )
                    )
                  }
                ></i>
                Refresh to fetch updated data!
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      {/* Counselors Performance Section */}
      <Row>
        <Col md="12">
          <Card className="card-tasks">
            <Card.Header>
              <Card.Title as="h4">Counselors</Card.Title>
              <p className="card-category">Performance</p>
            </Card.Header>
            <hr />
            {!statsLoaded ? (
              <LoadingIndicator text="Fetching counselor statistics..." />
            ) : (
              <Row className="d-flex">
                {userStats?.map((counselor) => (
                  <Col
                    key={counselor?.userId}
                    lg="3"
                    sm="6"
                    style={{ marginLeft: "20px" }}
                  >
                    <Card className="card-stats">
                      <Card.Body>
                        <Row>
                          <Col xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="fas fa-headset"></i>
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <Card.Title as="h4">{counselor?.name}</Card.Title>
                              <p
                                className="card-category"
                                style={{ color: "#591C21" }}
                              >
                                Clients || {counselor?.posts.toLocaleString()}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr />
                        <div className="stats">
                          <Button size="sm" className="btn">
                            <i className="fas fa-user-tag mr-1"></i>
                            Sessions ~ {counselor?.sessions}
                          </Button>
                        </div>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
