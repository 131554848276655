import {
  FETCH_ALL,
  FETCH_ALL_POSTS,
  FETCH_BY_SEARCH,
  FETCH_POST,
  CREATE,
  UPDATE,
  DELETE,
  LIKE,
  START_LOADING,
  END_LOADING,
  NO_CLIENTS_FOUND,
  GET_USER_STATS,
} from "../constants/actionTypes.js";

export default (
  state = {
    isLoading: true,
    posts: [],
    postsData: [],
    ClientRecord: null,
    userStats: [],
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case FETCH_ALL:
      return {
        ...state,
        posts: action?.payload?.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        ClientRecord: null,
      };
    case FETCH_ALL_POSTS:
      return {
        ...state,
        postsData: action?.payload?.data, //postData is the same as posts ~ difference is it has no pagination/page limit filter. We need it on the Dashboard and report extract
        ClientRecord: null,
      };
    case FETCH_BY_SEARCH:
      return {
        ...state,
        posts: action.payload.data.data,
        isLoading: false,
        ClientRecord: null,
      };
    case NO_CLIENTS_FOUND:
      return {
        // ...state,
        // posts: [],
        isLoading: false,
        ClientRecord: action.payload,
      };

    case FETCH_POST:
      return { ...state, post: action?.payload?.post, isLoading: false };

    case CREATE:
      return { ...state, posts: [...state.posts, action.payload] };
    case UPDATE:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
      };
    case DELETE:
      return {
        ...state,
        posts: state.posts.filter((post) => post._id !== action.payload),
      };
    case GET_USER_STATS:
      return {
        ...state,
        userStats: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
