import {
  FETCH_ALL,
  START_LOADING,
  END_LOADING,
  CREATE,
  FETCH_POST,
  UPDATE,
  DELETE,
  FETCH_ALL_POSTS,
  FETCH_BY_SEARCH,
  NO_CLIENTS_FOUND,
  GET_USER_STATS,
} from "../constants/actionTypes.js";

import * as api from "../api/index.js";
import { toast } from "react-toastify";

export const createPost = (post, userId, role) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.createPost(post, userId, role);

    dispatch({ type: CREATE, payload: data });

    // history.push('/');
    dispatch({ type: END_LOADING });
    // history.push(`/posts/${data._id}`);
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

export const getPost = (id, userId, role, history) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchPost(id, userId, role, history);

    dispatch({ type: FETCH_POST, payload: { post: data } });
    dispatch({ type: END_LOADING });
    // history.push(`/admin/cards/${post._id}`)
  } catch (error) {
    console.log(error);
    toast.error(error);
    // toast.error(error.response.statusText);
    // history.push('/')
  }
};

export const getPosts = (userId, role, page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data, currentPage, numberOfPages },
    } = await api.fetchPosts(userId, role, page);

    dispatch({
      type: FETCH_ALL,
      payload: { data, currentPage, numberOfPages },
    });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getClientsBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchClientsBySearch(searchQuery);
    // console.log(data, "data from getClientsBySearch");

    dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response.data.message);
    toast.error(error.response.data.message);
    // Dispatch an action to the reducer to update the state
    dispatch({ type: NO_CLIENTS_FOUND, payload: error.response.data.message });
    dispatch({ type: END_LOADING });
  }
};

export const getPostsNoLimit = (userId, role, page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data, currentPage, numberOfPages },
    } = await api.fetchPostsNoLimit(userId, role, page);

    dispatch({
      type: FETCH_ALL,
      payload: { data, currentPage, numberOfPages },
    });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};
export const getAllPosts = (userId, role, page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data },
    } = await api.fetchAllPosts(userId, role, page);

    dispatch({
      type: FETCH_ALL_POSTS,
      payload: { data },
    });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const updatePost = (id, post, userId, role) => async (dispatch) => {
  try {
    const { data } = await api.fetchUpdatePost(id, post, userId, role);

    dispatch({ type: UPDATE, payload: data });
    toast.success("Successfully Updated");
  } catch (error) {
    console.log(error);
    toast.error("Only Admins can Update!");
  }
};

export const deletePost = (id, userId, role) => async (dispatch) => {
  try {
    const { data } = await api.deletePost(id, userId, role);

    dispatch({ type: DELETE, payload: id, data });
    toast.success("Successfully Deleted");
  } catch (error) {
    console.log(error.response.statusText);
    toast.error(error.response.statusText);
    // toast.error(error.response.statusText)
  }
};

export const getUserStats = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getUserStatsApi();
    dispatch({ type: GET_USER_STATS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};
