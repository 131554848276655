import React, { useState } from "react";
import { Button, Card, Form, Row, Col, FormLabel } from "react-bootstrap";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import Select from "react-select";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { createPost } from "../../actions/posts";
import { districtOptions } from "./districts";
// toast.configure();

const ClientForm = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  const [postData, setPostData] = useState({
    callerName: "",
    mobile: "",
    message: "",
    reason: "",
    howLong: "",
    callerSex: "",
    clientSex: "",
    caseSource: "",
    peerReferral: "",
    sameAsCaller: "",
    clientName: "",
    clientDistrict: "",
    relationship: "",
    language: "",
    callerAge: "",
    clientAge: "",
    difficulty: [],
    howDidYouHear: [],
    servicesPrior: [],
    servicesOffered: [],
    nationality: "",
    region: "",
    accessed: [],
    caseAssessment: [],
    // scheduler: new Date(),// scheduler: [new Date("2020-05-11T12:00:00")],
  });

  const [sessionList, setSessionList] = useState([{ session: "" }]);

  const [hidden, setHidden] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const clear = () => {
    setPostData({
      callerName: "",
      mobile: "",
      message: "",
      reason: "",
      howLong: "",
      callerSex: "",
      caseSource: "",
      peerReferral: "",
      sameAsCaller: "",
      clientName: "",
      clientDistrict: "",
      relationship: "",
      language: "",
      callerAge: "",
      clientAge: "",
      clientSex: "",
      difficulty: [],
      howDidYouHear: [],
      servicesPrior: [],
      servicesOffered: [],
      nationality: "",
      region: "",
      accessed: [],
      caseAssessment: [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      createPost(
        {
          //Order is userId/role
          ...postData,
          name: user?.result?.name,
          sessionList: sessionList,
        },
        user.result._id,
        user.result.user_role,
        // history.push('/admin/cards')
        history.push("/admin/adminTable")
      )
    );
    clear();
  };

  const handleChange = async (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setPostData({
      ...postData,
      [name]: value,
    });
  };

  const handleChangeMulti = async (e, name) => {
    const value = Array.isArray(e) ? e.map((x) => x.value) : [];
    setPostData({
      ...postData,
      [name]: value,
    });
  };

  const handleSessionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...sessionList];
    list[index][name] = value;
    setSessionList(list);
  };

  const handleSessionRemove = (index) => {
    const list = [...sessionList];
    list.splice(index, 1);
    setSessionList(list);
  };

  const handleSessionAdd = () => {
    setSessionList([...sessionList, { session: "" }]);
  };

  return (
    <Col md="10">
      <Form
        action=""
        id="RegisterValidation"
        autoComplete="off"
        method=""
        onSubmit={handleSubmit}
      >
        <Card>
          <Card.Header>
            <Card.Title as="h4">Client Form</Card.Title>
          </Card.Header>

          {/* Case Source*/}
          <Card.Body className="pt-0 pb-0">
            <Col md={4}>
              <Card.Body>
                <FormLabel>1. Case Source (call or Walk in)</FormLabel>
                <Form.Check className="form-check-radio">
                  <RadioGroup
                    name="caseSource"
                    label="CaseSource"
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Call-in"
                      checked={postData.caseSource === "Call-in" ? true : false}
                      control={<Radio />}
                      label="Call In"
                      onChange={handleChange}
                    />
                    <FormControlLabel
                      value="Walk-in"
                      checked={postData.caseSource === "Walk-in" ? true : false}
                      control={<Radio />}
                      label="Walk In"
                      onChange={handleChange}
                    />
                  </RadioGroup>
                </Form.Check>
              </Card.Body>
            </Col>
          </Card.Body>

          {/* Caller's Phone Number */}
          <Card.Body className="pt-0">
            <Col md={5}>
              <Form.Group>
                <Card.Body>
                  <label>
                    2. Caller's Phone Number<span className="star">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="mobile"
                    // type="number"
                    value={postData.mobile}
                    onChange={(e) =>
                      setPostData({ ...postData, mobile: e.target.value })
                    }
                  ></Form.Control>
                </Card.Body>
              </Form.Group>
            </Col>

            {/* Caller's Name */}
            <Col md={5}>
              <Form.Group>
                <Card.Body>
                  <label>
                    3. Caller Name <span className="star">*</span>
                  </label>
                  <Form.Control
                    name="callerName"
                    type="text"
                    value={postData.callerName}
                    onChange={(e) =>
                      setPostData({ ...postData, callerName: e.target.value })
                    }
                  ></Form.Control>
                </Card.Body>
              </Form.Group>
            </Col>

            {/* Caller's Language */}
            <Col md={5} className="mb-3">
              <Card.Body>
                <label>
                  4. Caller Language<span className="star"></span>
                </label>
                <Select
                  className="basic-multi-select"
                  classNamePrefix="react-select"
                  name="language"
                  // isMulti
                  // value={postData.language}
                  // defaultValue={postData.language.map(item => ({value: item, label: item}))}
                  defaultValue={{
                    value: postData.language,
                    label: postData.language,
                  }}
                  onChange={(e) =>
                    setPostData({ ...postData, language: e.value })
                  }
                  // onChange={(e) => handleChangeMulti(e, 'language')}
                  options={[
                    // { value: "Ateso", label: "Ateso" },
                    { value: "English", label: "English" },
                    { value: "Luganda", label: "Luganda" },
                    // { value: "Lumasaba", label: "Lumasaba" },
                    { value: "Luo", label: "Luo" },
                    { value: "Runyakitara", label: "Runyakitara" },
                    { value: "Kiswahili", label: "Kiswahili" },
                    { value: "Others", label: "Others" },
                    // { value: "Not disclosed", label: "Not disclosed" },
                  ]}
                  placeholder="Select Language.."
                />
              </Card.Body>
            </Col>
          </Card.Body>

          {/* Caller Sex */}
          <Card.Body className="pt-0 mb-0">
            <Col md={3}>
              <Card.Body>
                <FormLabel>5. Sex</FormLabel>
                <Form.Check className="form-check-radio">
                  <RadioGroup
                    name="callerSex"
                    label="Gender"
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="female"
                      checked={postData.callerSex === "female" ? true : false}
                      control={<Radio />}
                      label="Female"
                      onChange={handleChange}
                    />
                    <FormControlLabel
                      value="male"
                      checked={postData.callerSex === "male" ? true : false}
                      control={<Radio />}
                      label="Male"
                      onChange={handleChange}
                    />
                    <FormControlLabel
                      value="undisclosed"
                      checked={
                        postData.callerSex === "undisclosed" ? true : false
                      }
                      control={<Radio />}
                      label="Undisclosed"
                      onChange={handleChange}
                    />
                  </RadioGroup>
                </Form.Check>
              </Card.Body>
            </Col>

            {/* Caller's Age */}
            <Col md={4}>
              <Card.Body>
                <label>
                  6. Caller Age (Age categories) <span className="star">*</span>
                </label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="callerAge"
                  defaultValue={{
                    value: postData.callerAge,
                    label: postData.callerAge,
                  }}
                  onChange={(e) =>
                    setPostData({ ...postData, callerAge: e.value })
                  }
                  options={[
                    { value: "Below 18", label: "Below 18" },
                    { value: "18-24 Years", label: "18-24 Years" },
                    { value: "25-30 Years", label: "25-30 Years" },
                    { value: "31-35 Years", label: "31-35 Years" },
                    { value: "Above 35 Years", label: "Above 35 Years" },
                    // { value: "Not disclosed", label: "Not disclosed" },
                  ]}
                  placeholder="Select Caller Age..."
                />
              </Card.Body>
            </Col>

            {/* Is the Caller The same as the client */}
            <Col md={4}>
              <Card.Body>
                <FormLabel>
                  7. Is the caller different from the client?
                </FormLabel>
                <Form.Check className="form-check-radio">
                  <RadioGroup
                    name="sameAsCaller"
                    label="Caller Different from Client"
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      checked={postData.sameAsCaller === "Yes" ? true : false}
                      control={<Radio />}
                      label="Yes"
                      onClick={() => setHidden(true)}
                      onChange={handleChange}
                    />
                    <FormControlLabel
                      value="No"
                      checked={postData.sameAsCaller === "No" ? true : false}
                      control={<Radio />}
                      label="No"
                      onClick={() => setHidden(false)}
                      onChange={handleChange}
                    />
                  </RadioGroup>
                </Form.Check>
              </Card.Body>
            </Col>

            {/* Relationship */}
            {hidden ? (
              <Col md={5}>
                <Card.Body>
                  <label>
                    8. Relationship between caller and client
                    <span className="star"></span>
                  </label>
                  <Select
                    className="react-select primary mb-3"
                    classNamePrefix="react-select"
                    name="relationship"
                    defaultValue={{
                      value: postData.relationship,
                      label: postData.relationship,
                    }}
                    onChange={(e) =>
                      setPostData({ ...postData, relationship: e.value })
                    }
                    options={[
                      {
                        value: "Parent(father/mother)",
                        label: "Parent(father/mother)",
                      },
                      {
                        value: "Spouse",
                        label: "Spouse",
                      },
                      { value: "Brother/Sister", label: "Brother/Sister" },
                      {
                        value: "Girlfriend/Boyfriend",
                        label: "Girlfriend/Boyfriend",
                      },
                      {
                        value: "Grandparent(Grandfather/Grandmother)",
                        label: "Grandparent(Grandfather/Grandmother)",
                      },
                      {
                        value: "Guardian",
                        label: "Guardian",
                      },
                      { value: "Peer/Friend", label: "Peer/Friend" },
                      { value: "Neighbor", label: "Neighbor" },
                      { value: "Teacher", label: "Teacher" },
                      {
                        value: "Community Health Worker",
                        label: "Community Health Worker",
                      },
                      { value: "Workmate", label: "Workmate" },
                      { value: "Others", label: "Others" },
                      { value: "Not disclosed", label: "Not disclosed" },
                    ]}
                    placeholder="Select Relationship.."
                  />
                </Card.Body>
              </Col>
            ) : (
              ""
            )}

            {/* Client's Name */}
            {hidden ? (
              <Col md={11} className="mb-2">
                <Card.Body>
                  <label>
                    9. Client Name <span className="star"></span>
                  </label>
                  <Form.Group>
                    <Form.Control
                      name="clientName"
                      type="text"
                      value={postData.clientName}
                      onChange={(e) =>
                        setPostData({
                          ...postData,
                          clientName: e.target.value,
                        })
                      }
                    ></Form.Control>
                  </Form.Group>
                </Card.Body>
              </Col>
            ) : (
              ""
            )}
            <Row>
              {/* Client Sex */}
              {/* {hidden ? ( */}
              <Col md={4}>
                <Card.Body>
                  <label>
                    10. Client Sex <span className="star">*</span>
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="clientSex"
                    // value={postData.age}
                    defaultValue={{
                      value: postData.clientSex,
                      label: postData.clientSex,
                    }}
                    onChange={(e) =>
                      setPostData({ ...postData, clientSex: e.value })
                    }
                    options={[
                      { value: "Female", label: "Female" },
                      { value: "Male", label: "Male" },
                      { value: "Not disclosed", label: "Not disclosed" },
                    ]}
                    placeholder="Select Sex"
                  />
                </Card.Body>
              </Col>
              {/* ) : (
                ""
              )} */}
              {/* Client Age */}
              {hidden ? (
                <Col md={4}>
                  <Card.Body>
                    <label>
                      11. Client Age <span className="star">*</span>
                    </label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="clientAge"
                      // value={postData.age}
                      defaultValue={{
                        value: postData.clientAge,
                        label: postData.clientAge,
                      }}
                      onChange={(e) =>
                        setPostData({ ...postData, clientAge: e.value })
                      }
                      options={[
                        { value: "Below 18", label: "Below 18" },
                        { value: "18-24 Years", label: "18-24 Years" },
                        { value: "25-30 Years", label: "25-30 Years" },
                        { value: "31-35 Years", label: "31-35 Years" },
                        { value: "Above 35 Years", label: "Above 35 Years" },
                        { value: "Not disclosed", label: "Not disclosed" },
                      ]}
                      placeholder="Select Client Age.."
                    />
                  </Card.Body>
                </Col>
              ) : (
                ""
              )}

              {/* Difficulty */}
              {/* <span className="text-danger">*</span> */}

              <Col md={4}>
                <Card.Body>
                  <label>
                    12. Do have trouble/difficulty with any of the following?{" "}
                    <span className="star"></span>
                  </label>
                  <Select
                    className="basic-multi-select"
                    classNamePrefix="react-select"
                    name="difficulty"
                    isMulti
                    // value={postData.difficulty}
                    defaultValue={postData.difficulty.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(e) => handleChangeMulti(e, "difficulty")}
                    options={[
                      {
                        value:
                          "Vision - Difficulty Seeing, even if wearing glasses?",
                        label:
                          "Vision - Difficulty Seeing, even if wearing glasses?",
                      },
                      {
                        value:
                          "Hearing - difficulty hearing, even if using a hearing aid(s)?",
                        label:
                          "Hearing- difficulty hearing, even if using a hearing aid(s)?",
                      },
                      {
                        value:
                          "Mobility - difficulty walking or climbing steps?",
                        label:
                          "Mobility - difficulty walking or climbing steps?",
                      },
                      {
                        value:
                          "Self-care- difficulty (with self-care such as) washing all over or dressing?",
                        label:
                          "Self-care- difficulty (with self-care such as) washing all over or dressing?",
                      },
                      {
                        value:
                          "Communication- difficulty communicating, for example understanding or being understood",
                        label:
                          "Communication- difficulty communicating, for example understanding or being understood",
                      },
                      {
                        value:
                          "Cognition (remembering)- difficulty remembering or concentrating?",
                        label:
                          "Cognition (remembering)- difficulty remembering or concentrating?",
                      },
                      {
                        value:
                          "Affect (anxiety and depression)- feeling worried, nervous or anxious?",
                        label:
                          "Affect (anxiety and depression)- feeling worried, nervous or anxious?",
                      },
                      { value: "Albinism", label: "Albinism" },
                    ]}
                    placeholder="Select difficulty"
                  />
                </Card.Body>
              </Col>
            </Row>

            {/* Client's Nationality */}
            <Row>
              <Col md={4}>
                <Card.Body>
                  <label>
                    13. Client Nationality<span className="star"></span>
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="nationality"
                    // value={postData.nationality}
                    defaultValue={{
                      value: postData.nationality,
                      label: postData.nationality,
                    }}
                    onChange={(e) =>
                      setPostData({ ...postData, nationality: e.value })
                    }
                    options={[
                      { value: "Ugandan", label: "Ugandan" },
                      { value: "Congolese", label: "Congolese" },
                      { value: "Rwandese", label: "Rwandese" },
                      { value: "Sudanese", label: "Sudanese" },
                      { value: "Kenyan", label: "Kenyan" },
                      { value: "Somali", label: "Somali" },
                      { value: "Tanzanian ", label: "Tanzanian " },
                      { value: "Others", label: "Others" },
                    ]}
                    placeholder="Select nationality"
                  />
                </Card.Body>
              </Col>

              {/* Client's Region */}
              <Col md={4}>
                <Card.Body>
                  <label>
                    14. Client Region <span className="star"></span>
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="region"
                    // value={postData.region}
                    defaultValue={{
                      value: postData.region,
                      label: postData.region,
                    }}
                    onChange={(e) =>
                      setPostData({ ...postData, region: e.value })
                    }
                    options={[
                      { value: "Central", label: "Central" },
                      { value: "Eastern", label: "Eastern" },
                      { value: "Northern", label: "Northern" },
                      { value: "Western", label: "Western" },
                      { value: "Others", label: "Others" },
                    ]}
                    placeholder="Select region"
                  />
                </Card.Body>
              </Col>

              {/* Client District */}
              <Col md={4} className="mb-2">
                <Card.Body>
                  <label>
                    15. Client District <span className="star">*</span>
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="clientDistrict"
                    defaultValue={{
                      value: postData.clientDistrict,
                      label: postData.clientDistrict,
                    }}
                    onChange={(e) =>
                      setPostData({ ...postData, clientDistrict: e.value })
                    }
                    options={districtOptions}
                    placeholder="Select Client District"
                  />
                </Card.Body>
              </Col>
            </Row>
          </Card.Body>
          <Card.Body>
            <Row>
              {/* Reason Why Client is Calling*/}
              <Form.Group>
                <Card.Body>
                  <label>
                    16. How did you get to know about this helpline?{" "}
                  </label>
                  <Select
                    className="basic-multi-select"
                    classNamePrefix="react-select"
                    name="howDidYouHear"
                    isMulti
                    defaultValue={postData.howDidYouHear.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(e) => handleChangeMulti(e, "howDidYouHear")}
                    options={[
                      { value: "Social Media", label: "Social Media" },
                      {
                        value:
                          "Marketing campaigns- Television, Radio, Billboards etc,",
                        label:
                          "Marketing campaigns- Television, Radio, Billboards etc,",
                      },
                      {
                        value: "Friend/Colleague,",
                        label: "Friend/Colleague,",
                      },
                      {
                        value:
                          "Information, Education, Communication materials- brochures, posters",
                        label:
                          "Information, Education, Communication materials- brochures, posters",
                      },
                      { value: "MHU Website", label: "MHU Website" },
                      { value: "Referred by Prudential", label: "Referred by Prudential" },
                      { value: "Other", label: "Other" },
                    ]}
                    placeholder="How did you hear about this helpline?"
                  />
                </Card.Body>
              </Form.Group>
            </Row>
            <Row>
              {/* Reason Why Client is Calling*/}
              <Form.Group>
                <Card.Body>
                  <label>17. Why is the client calling? </label>
                  <Form.Control
                    name="reason"
                    type="text"
                    placeholder="Type reason....."
                    value={postData.reason}
                    onChange={(e) =>
                      setPostData({ ...postData, reason: e.target.value })
                    }
                  ></Form.Control>
                </Card.Body>
              </Form.Group>
            </Row>
            <Col md={12}>
              <Card.Body>
                <label>
                  18. Case category (Information, legal, medical, psychosocial
                  support e.t.c. (Use the information from the previous question
                  to select the appropriate case category)
                </label>
                <Select
                  className="basic-multi-select"
                  classNamePrefix="react-select"
                  name="accessed"
                  isMulti
                  // value={postData.accessed}
                  defaultValue={postData.accessed.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(e) => handleChangeMulti(e, "accessed")}
                  options={[
                    {
                      value: "Seeking Information",
                      label: "Seeking Information",
                    },
                    {
                      value: "Seeking Mental Health Treatment",
                      label: "Seeking Mental Health Treatment",
                    },
                    { value: "Legal aid", label: "Legal aid" },
                    {
                      value: "Psychosocial Support",
                      label: "Psychosocial Support",
                    },
                    { value: "Peer Support", label: "Peer Support" },
                    {
                      value: "Safety & Protection",
                      label: "Safety & Protection",
                    },
                    {
                      value: "Livelihood/economic",
                      label: "Livelihood/economic",
                    },
                    { value: "School Fees", label: "School Fees" },
                    { value: "Rehabilitation", label: "Rehabilitation" },
                    {
                      value: "Traditional medicine/Spiritual healing",
                      label: "Traditional medicine/Spiritual healing",
                    },
                  ]}
                  placeholder="Select case category..."
                />
              </Card.Body>
            </Col>
            <Col md={12}>
              <Card.Body>
                <label>
                  19. What is the nature of the client’s case (Case assessment)-
                  Multiple response?
                </label>
                <Select
                  className="basic-multi-select"
                  classNamePrefix="react-select"
                  name="caseAssessment"
                  isMulti
                  // value={postData.accessed}
                  defaultValue={postData.caseAssessment.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(e) => handleChangeMulti(e, "caseAssessment")}
                  options={[
                    {
                      value: "Anxiety",
                      label: "Anxiety",
                    },
                    {
                      value: "Autism spectrum disorder",
                      label: "Autism spectrum disorder",
                    },
                    {
                      value: "Bipolar",
                      label: "Bipolar",
                    },
                    {
                      value: "Dementia",
                      label: "Dementia",
                    },
                    {
                      value: "Depression",
                      label: "Depression",
                    },
                    {
                      value: "Eating Disorders",
                      label: "Eating Disorders",
                    },
                    {
                      value: "Emotional Related Disorders",
                      label: "Emotional Related Disorders",
                    },
                    {
                      value:
                        "Addiction Related Disorders (alcohol, drugs, gambling, phone, video games)",
                      label:
                        "Addiction Related Disorders (alcohol, drugs, gambling, phone, video games)",
                    },
                    {
                      value: "Behavioral and Conduct Disorders (e.g. OCD)",
                      label: "Behavioral and Conduct Disorders (e.g. OCD)",
                    },
                    {
                      value: "Intellectual Disability",
                      label: "Intellectual Disability",
                    },
                    {
                      value: "Personality Disorder",
                      label: "Personality Disorder",
                    },
                    {
                      value: "Physical abuse",
                      label: "Physical abuse",
                    },
                    {
                      value: "Post-Traumatic Stress Disorders (PTSD)",
                      label: "Post-Traumatic Stress Disorders (PTSD)",
                    },
                    {
                      value: "Schizophrenia",
                      label: "Schizophrenia",
                    },
                    {
                      value: "Self-harm",
                      label: "Self-harm",
                    },
                    {
                      value: "Sexual assault/Sexual orientation",
                      label: "Sexual assault/Sexual orientation",
                    },
                    {
                      value: "Sleeping Disorders",
                      label: "Sleeping Disorders",
                    },
                    {
                      value: "Stress Related Disorders",
                      label: "Stress Related Disorders",
                    },
                    {
                      value: "Substance use disorder / Alcohol abuse disorder",
                      label: "Substance use disorder / Alcohol abuse disorder",
                    },
                    {
                      value: "Suicidal thoughts",
                      label: "Suicidal thoughts",
                    },
                    {
                      value: "Others",
                      label: "Others",
                    },
                  ]}
                  placeholder="Select case Assesment..."
                />
              </Card.Body>
            </Col>
          </Card.Body>
          {/* How Long?*/}

          <Card.Body>
            <Col md={6}>
              <Card.Body>
                <label>
                  20. How long (months) has the client taken with the
                  condition/case?{" "}
                </label>
                {/* <Form.Control
                  name="howLong"
                  type="text"
                  placeholder="Enter duration (months)"
                  value={postData.howLong}
                  onChange={(e) =>
                    setPostData({ ...postData, howLong: e.target.value })
                  }
                ></Form.Control> */}
                <Select
                  className="basic-multi-select"
                  classNamePrefix="react-select"
                  name="howLong"
                  // isMulti
                  defaultValue={{
                    value: postData.howLong,
                    label: postData.howLong,
                  }}
                  onChange={(e) =>
                    setPostData({ ...postData, howLong: e.value })
                  }
                  options={[
                    {
                      value: "Less than 2 weeks",
                      label: "Less than 2 weeks",
                    },
                    {
                      value: "Two weeks - 1 month",
                      label: "Two weeks - 1 month",
                    },
                    {
                      value: "2 - 6 months",
                      label: "2 - 6 months",
                    },
                    {
                      value: "7 - 12 months",
                      label: "7 - 12 months",
                    },
                    {
                      value: "More than 12 Months",
                      label: "More than 12 Months",
                    },
                  ]}
                  placeholder="Duration with condition... "
                />
              </Card.Body>
            </Col>

            {/* Services Accessed Prior to the call */}
            <Col md={5}>
              <Card.Body>
                <label>
                  21. What services has the client accessed prior to the
                  call/physical visit to MHU?
                  <span className="star"></span>
                </label>
                <Select
                  className="basic-multi-select"
                  classNamePrefix="react-select"
                  name="servicesPrior"
                  isMulti
                  // value={postData.difficulty}
                  defaultValue={postData.servicesPrior.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(e) => handleChangeMulti(e, "servicesPrior")}
                  options={[
                    { value: "None", label: "None" },
                    {
                      value: "Mental Health Treatment",
                      label: "Mental Health Treatment",
                    },
                    {
                      value: "Psychosocial Support ",
                      label: "Psychosocial Support ",
                    },
                    { value: "Rehabilitation", label: "Rehabilitation" },
                    { value: "Legal Services", label: "Legal Services" },
                    {
                      value: "Safety & Protection Services",
                      label: "Safety & Protection Services",
                    },
                    {
                      value: "Livelihood/Economic empowerment",
                      label: "Livelihood/Economic empowerment",
                    },
                    {
                      value: "information on mental health",
                      label: "Information on mental health",
                    },
                    { value: "Peer support", label: "Peer Support" },
                    {
                      value: "Traditional Medicine / Spiritual Healing",
                      label: "Traditional Medicine / Spiritual Healing",
                    },
                    {
                      value: "Prayer from religious leaders",
                      label: "Prayer from religious leaders",
                    },
                    { value: "Others", label: "Others" },
                  ]}
                  // className="basic-multi-select"

                  placeholder="Select Prior Services.. "
                />
              </Card.Body>
            </Col>

            <Col md={8}>
              <Card.Body>
                <label>
                  22. What services/support was provided by MHU during the call
                  or physical visit by the client?
                  <span className="star"></span>
                </label>
                <Select
                  className="basic-multi-select"
                  classNamePrefix="react-select"
                  name="servicesOffered"
                  isMulti
                  // value={postData.accessed}
                  defaultValue={postData.servicesOffered.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(e) => handleChangeMulti(e, "servicesOffered")}
                  options={[
                    {
                      value: "Psychosocial support/Counselling",
                      label: "Psychosocial support/Counselling",
                    },
                    { value: "Referral", label: "Referral" },
                    { value: "Information", label: "Information" },
                    { value: "Other", label: "Other" },
                  ]}
                  placeholder="Select service"
                />
              </Card.Body>
            </Col>
            {/* Remarks*/}
            <Card.Body>
              <label>23. Remarks</label>
              <Form.Control
                style={{ width: "700px", height: "150px" }}
                name="message"
                type="text"
                as="textarea"
                placeholder="Type other comments....."
                value={postData.message}
                onChange={(e) =>
                  setPostData({ ...postData, message: e.target.value })
                }
              ></Form.Control>
            </Card.Body>

            {/* PEER REFERRAL */}
            <Card.Body className="pt-0 pb-0">
              <Col md={4}>
                <Card.Body>
                  <FormLabel>24. Peer Referral</FormLabel>
                  <Form.Check className="form-check-radio">
                    <RadioGroup
                      name="peerReferral"
                      label="peerReferral"
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        checked={postData.peerReferral === "Yes" ? true : false}
                        control={<Radio />}
                        label="Yes"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        value="No"
                        checked={postData.peerReferral === "No" ? true : false}
                        control={<Radio />}
                        label="No"
                        onChange={handleChange}
                      />
                    </RadioGroup>
                  </Form.Check>
                </Card.Body>
              </Col>
            </Card.Body>
            {/* SESSIONS */}
            <Card.Body>
              <div className="form-field">
                <label>25. Session(s)</label>
                {sessionList.map((singleSession, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 5px 0.5rem 0",
                      }}
                    >
                      <Form.Control
                        // style={{ width: "100px", height: "30px" }}
                        style={{ width: "700px", height: "150px" }}
                        name="session"
                        type="text"
                        as="textarea"
                        placeholder="Session remarks....."
                        value={singleSession.session}
                        onChange={(e) => handleSessionChange(e, index)}
                      ></Form.Control>

                      {/* <Input
                        name="session"
                        type="text"
                        id="session"
                        value={singleSession.session}
                        onChange={(e) => handleServiceChange(e, index)}
                        required
                      /> */}
                      {sessionList.length - 1 === index &&
                        sessionList.length < 7 && (
                          <Button
                            type="button"
                            onClick={handleSessionAdd}
                            // className="add-btn"
                          >
                            <span>+ Add Session</span>
                          </Button>
                        )}
                    </div>
                    <div className="second-division">
                      {sessionList.length !== 1 && (
                        <Button
                          type="button"
                          onClick={() => handleSessionRemove(index)}
                          className="remove-btn"
                          variant="danger"
                        >
                          <span>Remove</span>
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Card.Body>

            <Card.Footer className="text-right">
              <Button
                className="btn-fill pull-right"
                variant="danger"
                // type="submit"
                onClick={clear}
                style={{ marginRight: "5px" }}
              >
                Clear
              </Button>
              <Button
                className="btn-fill pull-right"
                variant="info"
                type="submit"
                disabled={postData.clientSex === ""}
                // onClick={() => {}}
              >
                Submit
              </Button>
              <div className="clearfix"></div>
            </Card.Footer>
          </Card.Body>
        </Card>
      </Form>
    </Col>
  );
  // );
};

export default ClientForm;
