import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  ButtonBase,
} from "@material-ui/core/";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

import useStyles from "./postStyle.js";
import { getPost, deletePost } from "../../../../actions/posts.js";

const Post = ({ post }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  // const user = JSON.parse(localStorage.getItem('profile'));
  const history = useHistory();

  const openPostDetail = (e) => {
    dispatch(
      getPost(
        post._id, //Ordering => postID/userId/Role
        user.result._id,
        user.result.user_role,
        // history,
        history.push(`/cards/${post._id}`)
      )
    );
    // dispatch(getPost(post._id, history));
  };

  return (
    <Card className={classes.card} raised elevation={6}>
      <ButtonBase
        component="span"
        name="test"
        className={classes.cardAction}
        onClick={openPostDetail}
      >
        <CardMedia
          className={classes.media}
          image={
            post.selectedFile ||
            "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
          }
          title={post.title}
        />
        <div className={classes.overlay}>
          <Typography variant="body2" style={{ textTransform: "capitalize" }}>
            Client Name: {post.clientName}
          </Typography>
          <Typography variant="body2" style={{ textTransform: "capitalize" }}>
            Caller Gender: {post.callerSex}
          </Typography>
          {/* <Typography variant="body2">Language: {post.language}</Typography> */}
          {/* <Typography variant="body2">Age: {post.callerAge}</Typography> */}
          <Typography variant="body2">Creator: {post.name}</Typography>
          <Typography variant="body2">
            Created: {moment(post.createdAt).fromNow()}
          </Typography>
        </div>
      </ButtonBase>
      {(user?.result?._id === post?.creator ||
        user?.result?._id === post?.creator) && (
        <div className={classes.overlay2} name="edit">
          <Button
            onClick={(e) => {
              // e.stopPropagation();
              e.preventDefault();
              dispatch(
                getPost(
                  post._id,
                  user.result._id,
                  user.result.user_role,
                  // history,
                  history.push(`/cards/edit/${post._id}`)
                )
              );
              // setCurrentId(post._id);
            }}
            style={{ color: "white" }}
            size="small"
          >
            <MoreHorizIcon fontSize="medium" />
          </Button>
        </div>
      )}
      {/* <div className={classes.details}>
          <Typography variant="body2" color="textSecondary" component="h2">{post.tags.map((tag) => `#${tag} `)}</Typography>
        </div> */}
      <Typography
        className={classes.title}
        gutterBottom
        variant="body2"
        component="h2"
      >
        {post.title}
      </Typography>
      <CardContent>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
          }}
        >
          {post.message}
        </Typography>
        {/* <Typography variant="body2" color="textSecondary" component="p">{post.message.split(' ').splice(0, 20).join(' ')}...</Typography> */}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          size="small"
          color="primary"
          disabled={!user?.result}
          onClick={() => dispatch(likePost(post._id))}
        >
          {/* <Likes /> */}
        </Button>
        {(user?.result?.googleId === post?.creator ||
          user?.result?._id === post?.creator) && (
          <Button
            size="small"
            color="secondary"
            onClick={() =>
              dispatch(
                deletePost(post._id, user.result._id, user.result.user_role)
              )
            }
          >
            <DeleteIcon fontSize="small" /> &nbsp; Delete
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default Post;
