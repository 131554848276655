// react-bootstrap components
import React, { useEffect, useState } from "react";
import {
  Button,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Col,
  Dropdown,
  Form,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { LOGOUT } from "constants/actionTypes";

function AdminNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  // console.log(user.result.name)

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  const login = () => {
    // dispatch({ type: actionType.LOGOUT });
    history.push("/auth/login-page");
    // setUser(null);
  };

  const logout = () => {
    dispatch({ type: LOGOUT });

    history.push("/auth/login-page");
    setUser(null);
  };

  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                variant="dark"
                onClick={() => document.body.classList.toggle("sidebar-mini")}
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
              <Button
                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                variant="dark"
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
            </div>
            {/* <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              Toggle
            </Navbar.Brand> */}
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav navbar>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-41471887333"
                  variant="default"
                >
                  <i className="nc-icon nc-bullet-list-67"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  // alignRight
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <div className="divider"></div>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-lock-circle-open"></i>
                    Lock Screen
                  </Dropdown.Item>
                  {user?.result ? (
                    <Dropdown.Item
                      className="text-danger"
                      href="#logout"
                      onClick={logout}
                      // onClick={(e) => e.preventDefault()}
                    >
                      <i className="nc-icon nc-button-power"></i>
                      Log out
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      className="text-danger"
                      href="#login"
                      onClick={login}
                      // onClick={(e) => e.preventDefault()}
                    >
                      <i className="nc-icon nc-button-power"></i>
                      Log In
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
            <Nav className="nav mr-auto" navbar>
              <Form
                className="navbar-form navbar-left navbar-search-form ml-3 ml-lg-0"
                role="search"
              >
                {/* <InputGroup>
                  <i className="nc-icon nc-zoom-split"></i>
                  <Form.Control
                    defaultValue=""
                    placeholder="Search.XX.."
                    type="text"
                  ></Form.Control>
                </InputGroup> */}
              </Form>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
