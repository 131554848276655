import { makeStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';

export default makeStyles((theme) => ({

  logout: {
    marginLeft: '20px',
  },

  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    // top: '5%',
    left: '-10%',
    bottom: '20%',
    backgroundColor: "#0A9B9B",
  },

}));
