import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { getUser, getUsers, deleteUser } from "../../actions/auth";
import {
  Button,
  Card,
  Col,
  Container,
  ButtonToolbar,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

const Users = () => {
  const { auth = [], isLoading } = useSelector((state) => state.auth);
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (user?.result?._id && user?.result?.user_role) {
      dispatch(getUsers(user.result._id, user.result.user_role));
    }
  }, [dispatch, user]);

  if (!user?.result) {
    return (
      <Container className="mt-5">
        <Typography variant="h6" align="center">
          Please sign in to view users.
        </Typography>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "60vh" }}
      >
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col md="11">
          <Card className="regular-table-with-color">
            <Card.Header>
              <Card.Title as="h4">Users</Card.Title>
              <p className="card-category">List of active Users</p>
            </Card.Header>
            <Col md="12">
              <Card.Body className="table-responsive p-0">
                {Array.isArray(auth) && auth.length > 0 ? (
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>
                          <strong>#</strong>
                        </th>
                        <th>
                          <strong>Full Name</strong>
                        </th>
                        <th>
                          <strong>Email</strong>
                        </th>
                        <th>
                          <strong>ID</strong>
                        </th>
                        <th>
                          <strong>Role</strong>
                        </th>
                        <th>
                          <strong>Action</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {auth.map((agent, index) => (
                        <tr key={agent?._id}>
                          <td>{index + 1}.</td>
                          <td>{agent?.name}</td>
                          <td>{agent?.email}</td>
                          <td>{agent?._id}</td>
                          <td>{agent?.user_role}</td>
                          <td>
                            <ButtonToolbar className="gap-2">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Edit User Info</Tooltip>}
                              >
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                      getUser(
                                        agent._id,
                                        user.result._id,
                                        user.result.user_role
                                      )
                                    );
                                    history.push(`/user-edit/${agent._id}`);
                                  }}
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip>Delete User</Tooltip>}
                              >
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                      deleteUser(
                                        agent._id,
                                        user.result._id,
                                        user.result.user_role
                                      )
                                    );
                                    history.push("/admin/users");
                                  }}
                                >
                                  <i className="fas fa-times"></i>
                                </Button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-center py-4">
                    <Typography variant="body1">No users found.</Typography>
                  </div>
                )}

                <Col style={{ paddingBottom: "15px" }}>
                  <Card.Body>
                    <Col className="font-icon-list" lg="2" md="3" sm="4" xs="6">
                      <Button
                        className="btn-outline btn-round btn-wd mr-1"
                        variant="danger"
                        onClick={() => history.push("/admin/user-form/")}
                      >
                        <i
                          className="fas fa-user"
                          style={{ marginRight: "5px" }}
                        />
                        Add Agent
                      </Button>
                    </Col>
                  </Card.Body>
                </Col>
              </Card.Body>
            </Col>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Users;
