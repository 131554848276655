import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Card,
  Table,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { CSVLink } from "react-csv";

import { useHistory, useLocation } from "react-router";
import {
  deletePost,
  getClientsBySearch,
  getPost,
  getPostsNoLimit,
} from "../../actions/posts.js";
import { Grid, Paper, Typography } from "@material-ui/core";
import useStyles from "../Forms/Posts/postsStyles.js";
import Paginate from "../../views/Forms/Posts/AdminTablePagination.jsx";
// import Paginate from "../../views/Forms/Posts/Pagination.jsx";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AdminTables = () => {
  const { posts, postsData, ClientRecord, isLoading } = useSelector(
    (state) => state.posts
  );
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  const userId = user?.result?._id;
  const [isFetching, setIsFetching] = useState(false);
  const [search, setSearch] = useState("");

  // const user = JSON.parse(localStorage.getItem('profile'));
  const query = useQuery();
  const page = query.get("page") || 1;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getPostsNoLimit(user?.result?._id, user?.result?.user_role)); // result._id => userId & result.user_role => User role
    // dispatch(getPosts(user?.result?._id, user?.result?.user_role)); // result._id => userId & result.user_role => User role
  }, []);

  const fetchCsv = async () => {
    setIsFetching(true);
    try {
      // const response = await fetch("http://localhost:8000/csv/download-csv");
      const response = await fetch(
        "https://mhu-data-server.herokuapp.com/csv/download-csv"
      );
      const csvData = await response.blob();
      const url = URL.createObjectURL(csvData);
      const link = document.createElement("a");
      link.href = url;
      link.download = "clients-data.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV file:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchClients();
    }
  };

  const backToListings = () => {
    history.push("/admin/adminTable");
    setSearch("");
  };

  const handleInputClear = (e) => {
    if (e.target.value === "") {
      history.push("/admin/adminTable"); // Navigate to the unfiltered listings page
      dispatch(
        getPostsNoLimit(user?.result?._id, user?.result?.user_role),
        setSearch("")
      ); // Fetch unfiltered posts and set search to empty string
    }
  };

  const searchClients = () => {
    if (search.trim()) {
      dispatch(getClientsBySearch({ search, user_id: userId }));
      history.push(`/admin/adminTable?searchQuery=${search || "none"}`);
    } else {
      history.push("/admin/adminTable");
    }
  };

  const highlightSearchTerm = (text, search) => {
    if (!search) {
      return text;
    }
    const parts = text.split(new RegExp(`(${search})`, "gi"));
    return (
      <span>
        {parts.map((part) =>
          part.toLowerCase() === search.toLowerCase() ? (
            <span className={classes.highlight} key={Math.random()}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="table-with-links">
                <Card.Header>
                  <Card.Title as="h4">Clients Table</Card.Title>
                  <p className="card-category">Active Clients!</p>
                </Card.Header>

                <Card.Body className="table-full-width">
                  {/* Search Icon */}
                  <div className={classes.search}>
                    <div className="input-group-prepend">
                      <input
                        type="search"
                        id="search"
                        className={classes.searchInput}
                        placeholder="Search Clients..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={handleKeyPress}
                        onInput={handleInputClear}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-behance"
                      onClick={searchClients}
                      style={{
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                      }}
                    >
                      <i
                        className="nc-icon nc-zoom-split"
                        style={{ color: "white" }}
                      ></i>
                    </button>
                  </div>
                  <Table>
                    <thead>
                      <tr className="info">
                        <th style={{ color: "black" }} className="text-center">
                          #
                        </th>
                        <th
                          style={{
                            color: "black",
                            fontStyle: "italic",
                            width: "40%",
                          }}
                        >
                          <strong> Caller Name</strong>
                        </th>
                        <th
                          style={{
                            color: "black",
                            fontStyle: "italic",
                            width: "60%",
                            textAlign: "center",
                          }}
                        >
                          <strong> Created</strong>
                        </th>
                        <th
                          style={{
                            color: "black",
                            fontStyle: "italic",
                            width: "30%",
                            textAlign: "center",
                          }}
                        >
                          <strong> Session(s)</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Counselor</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Mobile</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Caller Sex</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Client Sex</strong>
                        </th>
                        <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-right"
                        >
                          <strong>Caller Age</strong>
                        </th>
                        <th
                          style={{
                            color: "black",
                            fontStyle: "italic",
                          }}
                          className="text-right"
                        >
                          <strong>Case Duration</strong>
                        </th>
                        {/* <th style={{ color: 'black',  fontStyle: 'italic' }} className="text-right"><strong>Language</strong></th> */}
                        <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-right"
                        >
                          <strong>Region</strong>
                        </th>
                        <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-right"
                        >
                          <strong>Nationality</strong>
                        </th>
                        {/* <th style={{ color: 'black',  fontStyle: 'italic' }} className="text-right"><strong>Assessment</strong></th> */}
                        {/* <th style={{ color: 'black',  fontStyle: 'italic' }} className="text-right"><strong>Difficulty</strong></th> */}
                        <th
                          style={{
                            color: "black",
                            fontStyle: "italic",
                          }}
                          className="text-left"
                        >
                          <strong>Action</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ClientRecord === null ? (
                        // posts?.length > 0 ? (
                        posts?.map((post, index) => (
                          <tr key={post?._id}>
                            <td className="text-center">{index + 1}.</td>
                            <td>
                              {highlightSearchTerm(post?.callerName, search)}
                            </td>

                            <td
                              className="text-right"
                              style={{
                                textAlign: "center",
                                width: "60%",
                              }}
                            >
                              {/* {post?.createdAt.substring(0, 10)} */}
                              {post?.createdAt
                                .substring(0, 10)
                                .split("-")
                                .reverse()
                                .join("-")}
                            </td>

                            <td
                              className="text-right"
                              style={{ textAlign: "center", width: "40%" }}
                            >
                              {post?.sessionCount}
                            </td>
                            <td className="text-right">{post?.name}</td>
                            <td className="text-right">
                              {highlightSearchTerm(
                                String(post?.mobile),
                                search
                              )}
                            </td>
                            <td style={{ textTransform: "capitalize" }}>
                              {post?.callerSex}
                            </td>
                            <td style={{ textTransform: "capitalize" }}>
                              {post?.clientSex}
                            </td>
                            <td className="text-right">{post?.callerAge}</td>
                            <td className="text-right">{post?.howLong}</td>
                            {/* <td className="text-right">{post?.language}</td> */}
                            <td className="text-right">{post?.region}</td>
                            <td className="text-right">{post?.nationality}</td>
                            {/* <td className="text-right">{post?.accessed}</td> */}
                            {/* <td className="text-right">{post?.difficulty}</td> */}
                            <td className="td-actions text-right">
                              <OverlayTrigger
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                  <Tooltip id="tooltip-48903503">
                                    Details..
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-link btn-xs"
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                      getPost(
                                        post._id,
                                        user.result._id,
                                        user.result.user_role
                                      )
                                    ); //Ordering is postID/userId/role
                                    history.push(`/cards/${post._id}`);
                                  }}
                                  // onClick={(e) => e.preventDefault()}
                                  variant="info"
                                >
                                  <i className="fas fa-user"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                  <Tooltip id="tooltip-981231696">
                                    Edit Profile..
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-link btn-xs"
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                      getPost(
                                        post._id,
                                        user.result._id,
                                        user.result.user_role,
                                        history,
                                        history.push(`/cards/edit/${post._id}`)
                                      )
                                    );
                                  }}
                                  variant="success"
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                  <Tooltip id="tooltip-255158527">
                                    Delete..
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-link btn-xs"
                                  href="#pablo"
                                  onClick={() =>
                                    dispatch(
                                      deletePost(
                                        post._id,
                                        user.result._id,
                                        user.result.user_role
                                      )
                                    )
                                  }
                                  // onClick={(e) => e.preventDefault()}
                                  variant="danger"
                                >
                                  <i className="fas fa-times"></i>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))
                      ) : (
                        // Take up the whole row and center the text
                        <tr
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            height: "100px",
                          }}
                        >
                          <td colSpan="4">
                            <div className="text-center">
                              <Typography variant="h6">
                                No Results for
                                <span style={{ color: "red" }}>
                                  {search ? ` "${search}"` : null}{" "}
                                </span>
                              </Typography>

                              <br />
                              <Button
                                className="btn btn-round btn-wd mr-1"
                                type="button"
                                variant="danger"
                                onClick={handleInputClear}
                              >
                                Back!
                              </Button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {!search && (
                    <Col style={{ paddingBottom: "15px" }}>
                      <Card.Body>
                        <Col
                          className="font-icon-list"
                          lg="2"
                          md="3"
                          sm="4"
                          xs="6"
                        >
                          <Button
                            className="btn-outline btn-round btn-wd mr-1"
                            variant="danger"
                            onClick={() => history.push("/admin/client-form")}
                          >
                            {" "}
                            <i
                              className="nc-icon nc-simple-add"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Add Client
                          </Button>
                        </Col>
                      </Card.Body>

                      <Card.Body>
                        {/* <Button
                        className="btn btn-round btn-wd mr-1"
                        type="button"
                        variant="info"
                      >
                        <CSVLink
                          data={postsData}
                          style={{
                            textDecoration: "none",
                            fontSize: "12px",
                            color: "white",
                          }}
                          filename={"clients-data.csv"}
                        >
                          EXPORT
                        </CSVLink>
                        <span className="btn-label btn-label-right">
                          <i className="fas fa-arrow-down"></i>
                        </span>
                      </Button> */}

                        <Button
                          className="btn btn-round btn-wd mr-1"
                          type="button"
                          variant="info"
                          onClick={fetchCsv}
                          disabled={isFetching}
                        >
                          {isFetching ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="s"
                            ></span>
                          ) : (
                            <>
                              RUN REPORT
                              <span className="btn-label btn-label-right">
                                <i className="fas fa-arrow-down"></i>
                              </span>
                            </>
                          )}
                        </Button>
                      </Card.Body>
                    </Col>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}

      <Grid>
        <Paper
          style={{
            bottom: "0",
            right: "0",
            width: "100%",
            height: "100%",
            top: "85%",
            left: "80%",
            padding: "6px",
            borderRadius: "10px",
            align: "right",
            verticalAlign: "top",
          }}
          elevation={6}
        >
          <Paginate page={page} user={user} />
        </Paper>
      </Grid>
    </>

    // )
  );
};

export default AdminTables;
