import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { createUser } from '../../actions/auth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
import { toast } from 'react-toastify';

const UserForm = () => {
    const [user] = useState(JSON.parse(localStorage.getItem('profile')));
    const [postData, setPostData] = useState({ firstName: '', lastName: '', email: '',  password: '', confirmPassword: '', user_role: ''});


    const dispatch = useDispatch()
    const history = useHistory();

    const handleSubmit = async (e) => {
            e.preventDefault();
            const { password, confirmPassword } = postData
            if (password !== confirmPassword) {
              toast.error("Passwords don't match!")
            } else {
              dispatch(createUser({ 
                  ...postData,
                  name: user?.result?.name
               },
               user?.result?._id,
               user?.result?.user_role,
              history.push('/admin/users')
              ));
            }
    }; 

    return (
        <Container fluid>
            <Col md="6">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">Create Agent</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form action="#" method="#" autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group>
                    <label>First Name</label>
                    <Form.Control
                      placeholder="First name...."
                      name="firstName"
                      type="text"
                      value={postData.firstName}
                      onChange={(e) => setPostData({ ...postData, firstName: e.target.value })}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <label>Last Name</label>
                    <Form.Control
                      placeholder="Last Name"
                      name="lastName"
                      type="text"
                      value={postData.lastName}
                      onChange={(e) => setPostData({ ...postData, lastName: e.target.value })}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <label>Email address</label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      value={postData.email}
                      onChange={(e) => setPostData({ ...postData, email: e.target.value })}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <label>Password</label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                      value={postData.password}
                      onChange={(e) => setPostData({ ...postData, password: e.target.value })}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <label>Confirm Password</label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={postData.confirmPassword}
                      onChange={(e) => setPostData({ ...postData, confirmPassword: e.target.value })}
                    ></Form.Control>
                  </Form.Group>

                  <Col md="7">
                  <h4 className="title">Assign Roles</h4>
                  <Row>
                 
                    <Col md="10">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="user_role"
                        value={postData.value}
                        onChange={(value) => setPostData({...postData, user_role: value})}
                        options={[
                          // {
                          //   value: "",
                          //   label: "User Role",
                          //   isDisabled: true,
                          // },
                          { value: "ADMIN", label: "ADMIN" },
                          { value: "SUPERVISOR", label: "SUPERVISOR" },
                          { value: "CREATOR", label: "CREATOR" },
                        ]}
                        placeholder="Select Role"
                      />
                    </Col>
                  </Row>
                </Col>

                <Card.Footer>
                    <Button className="btn-fill" type="submit" variant="info">
                    Submit
                    </Button>
                </Card.Footer>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Container>
    )
}

export default UserForm
