import {
  AGENT_CREATION_FAIL,
  AUTH,
  CREATE,
  CREATE_USER,
  DELETE_USER,
  END_LOADING,
  FETCH_USERS,
  FETCH_USER_SINGLE,
  START_LOADING,
  UPDATE_USER,
  USER_LOGIN_FAIL,
} from "../constants/actionTypes.js";
import * as api from "../api/index.js";
import { toast } from "react-toastify";

export const signin = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.signIn(formData);

    dispatch({ type: AUTH, data });
    toast.success("Login Success");

    router.push("/");
  } catch (error) {
    console.log(error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(error.response.data.message);
    dispatch({ type: END_LOADING });
  }
};

export const signup = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);

    dispatch({ type: AUTH, data });
    toast.success("Account Created");

    router.push("/");
  } catch (error) {
    console.log(error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(error.response.data.message);
  }
};

export const createUser = (post, userId, role) => async (dispatch) => {
  try {
    if (role == "ADMIN") {
      dispatch({ type: START_LOADING });
      const { data } = await api.createUser(post, userId, role);

      dispatch({ type: CREATE_USER, payload: data });
      toast.success("Agent Created");

      // router.push('/admin/users');
    } else {
      toast.error("Only Admins can Create Users!");
      dispatch({ type: END_LOADING });
    }
  } catch (error) {
    console.log(error);
    toast.error(error);
    // dispatch({ type: AGENT_CREATION_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message })
    // toast.error(error.response.data.message)
  }
};

export const getUsers = (userId, role) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchUsers(userId, role);
    // console.log(data)

    dispatch({ type: FETCH_USERS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getUser = (id, userId, role, history) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchUser(id, userId, role, history);

    dispatch({ type: FETCH_USER_SINGLE, payload: { counselor: data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

export const profileUpdate =
  (resourceUserId, user, loggedInUserId, role) => async (dispatch) => {
    try {
      if (role == "ADMIN" || role == "CREATOR") {
        const { data } = await api.profileUpdate(
          resourceUserId,
          user,
          loggedInUserId,
          role
        );

        dispatch({ type: UPDATE_USER, payload: data });
        toast.success("Profile Updated Successfully!");
      } else {
        toast.error("You're not Authorized to Update!");
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

export const deleteUser = (id, userId, role) => async (dispatch) => {
  try {
    if (role == "ADMIN") {
      const { data } = await api.deleteUser(id, userId, role);

      dispatch({ type: DELETE_USER, payload: id, data });
      toast.success("Successfully Deleted");
    } else {
      toast.error("You're not Authorized to Delete!");
    }
  } catch (error) {
    console.log(error);
    toast.error(error);
    // toast.error(error.response.statusText)
  }
};
