import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from "react-router-dom";
import useStyles from "./paginationStyles";
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "actions/posts";
import { getPostsNoLimit } from "actions/posts";

const Paginate = ({ page }) => {
  const { numberOfPages } = useSelector((state) => state.posts);
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (page)
      dispatch(
        getPostsNoLimit(user?.result?._id, user?.result?.user_role, page)
      );
    // eslint-disable-next-line
  }, [page]);

  return (
    <Pagination
      classes={{ ul: classes.ul }}
      count={numberOfPages}
      page={Number(page) || 1}
      variant="text"
      color="secondary"
      renderItem={(item) => (
        <PaginationItem
          {...item}
          component={Link}
          to={`/admin/adminTable?page=${item.page}`}
        />
      )}
    />
  );
};

export default Paginate;
