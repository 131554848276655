export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH_ALL_POSTS = "FETCH_ALL_POSTS";
export const FETCH_BY_SEARCH = "FETCH_BY_SEARCH";
export const NO_CLIENTS_FOUND = "NO_CLIENTS_FOUND";
export const FETCH_POST = "FETCH_POST";
export const LIKE = "LIKE";
export const END_LOADING = "END_LOADING";
export const START_LOADING = "START_LOADING";

export const AUTH = "AUTH";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const CREATE_USER = "CREATE_USER";
export const FETCH_USER_SINGLE = "FETCH_USER_SINGLE";
export const AGENT_CREATION_FAIL = "AGENT_CREATION_FAIL";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const LOGOUT = "LOGOUT";
export const FETCH_USERS = "FETCH_USERS";

export const GET_USER_STATS = "GET_USER_STATS";
